import React, { useState } from "react";
import { Button, Snackbar, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  uploadBytes,
  getDownloadURL,
  ref,
  deleteObject,
} from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { storage, db } from "../../../../../config/fbConfig";

const validationSchema = Yup.object({
  company_name: Yup.string().required("Company Name is required"),
  company_phone: Yup.string().max(10).required("Phone is required"),
  company_Email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  company_website: Yup.string().url("Invalid URL format"),
  company_since: Yup.date().required("Established Since is required"),
  team_size: Yup.string().required("Team Size is required"),
  description: Yup.string().required("Description is required"),
  pan_number: Yup.string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN format")
    .required("PAN Number is required"),
  gst_number: Yup.string()
    .matches(
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$/,
      "Invalid GST format"
    )
    .required("GST Number is required"),
  logo: Yup.mixed()
    .nullable()
    .test(
      "fileSize",
      "File too large. Max size is 1MB.",
      (value) => !value || (value && value.size <= 1048576)
    )
    .test(
      "fileFormat",
      "Unsupported format. Only jpg, jpeg, and png are allowed.",
      (value) =>
        !value ||
        (value && ["image/jpg", "image/jpeg", "image/png"].includes(value.type))
    ),
});

const SectionEmployeeCompanyInfo = ({ Address, userUUID }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleFileUpload = async (file) => {
    const fileRef = ref(storage, `logos/${userUUID}/${file.name}`);
    await uploadBytes(fileRef, file);
    return await getDownloadURL(fileRef);
  };

  const handleDeleteLogo = async () => {
    if (Address?.Logo) {
      try {
        // Extract the file path from the Firebase Storage URL
        const path = Address.Logo.split("/o/")[1].split("?alt")[0];
        console.log("Logo path:", path); // Log path to verify it's correct

        const logoRef = ref(storage, path); // Create a reference to the logo file

        // Delete the logo from Firebase Storage
        await deleteObject(logoRef);
        setSnackbarMessage("Logo deleted successfully!");
        setLogoPreview(null); // Optionally clear the preview
        setIsEditMode(true); // Keep edit mode to allow for new uploads
      } catch (error) {
        setSnackbarMessage("Error deleting logo. Please try again.");
        console.error("Error deleting logo: ", error); // Log error for debugging
      } finally {
        setSnackbarOpen(true);
      }
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const logoUrl = values.logo
        ? await handleFileUpload(values.logo)
        : Address?.Logo; // Keep existing logo if no new upload

      const data = {
        Basic: {
          CompanyName: values.company_name,
          CompanyPhone: values.company_phone,
          CompanyEmail: values.company_Email,
          CompanyWebsite: values.company_website,
          CompanySince: values.company_since,
          TeamSize: values.team_size,
          Description: values.description,
          PANNumber: values.pan_number,
          GSTNumber: values.gst_number,
          Logo: logoUrl,
        },
      };

      await setDoc(doc(db, "Verify", userUUID), data, { merge: true });
      setSnackbarMessage("Company information updated successfully!");
      setIsEditMode(false);
    } catch {
      setSnackbarMessage(
        "Error updating company information. Please try again."
      );
    } finally {
      setSnackbarOpen(true);
      setSubmitting(false);
    }
  };

  const renderField = (name, type, label, disabled) => (
    <div className="col-lg-6 col-md-6">
      <div className="form-group">
        <label>{label}</label>
        <Field
          type={type}
          name={name}
          className="form-control"
          style={{ backgroundColor: isEditMode ? "#14ca4f54" : "#14ca4f2e" }}
          disabled={disabled}
        />
        <ErrorMessage name={name} component="div" className="text-danger" />
      </div>
    </div>
  );

  return (
    <>
      <div className="panel panel-default">
        <div className="panel-heading wt-panel-heading p-a20">
          <h4 className="panel-tittle m-a0">Basic Information</h4>
        </div>
        <div className="panel-body wt-panel-body p-a20 m-b30">
          <div className="edit-button-end">
            <Tooltip title={isEditMode ? "Close" : "Edit"}>
              <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                {isEditMode ? (
                  <CloseIcon fontSize="small" />
                ) : (
                  <EditIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              company_name: Address?.CompanyName || "",
              company_phone: Address?.CompanyPhone || "",
              company_Email: Address?.CompanyEmail || "",
              company_website: Address?.CompanyWebsite || "",
              company_since: Address?.CompanySince || "",
              team_size: Address?.TeamSize || "",
              description: Address?.Description || "",
              pan_number: Address?.PANNumber || "",
              gst_number: Address?.GSTNumber || "",
              logo: null,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, isSubmitting }) => (
              <Form>
                <div className="panel panel-default">
                  <div className="panel-heading wt-panel-heading p-a20">
                    <h4 className="panel-tittle m-a0">Logo and Cover Image</h4>
                  </div>
                  <div className="panel-body wt-panel-body p-a20 p-b0 m-b30">
                    <div className="form-group">
                      <label htmlFor="logo">Upload Logo</label>
                      <input
                        type="file"
                        id="logo"
                        name="logo"
                        style={{
                          backgroundColor:
                            isEditMode === true ? "#14ca4f" : "#14ca4f2e",
                        }}
                        accept=".jpg, .jpeg, .png"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue("logo", file);
                        }}
                        className="form-control"
                        disabled={!isEditMode}
                      />
                      <ErrorMessage
                        name="logo"
                        component="div"
                        className="text-danger"
                      />
                      <p>
                        <b>Company Logo:</b> Max file size is 1MB, Minimum
                        dimension: 136 x 136 and Suitable files are .jpg & .png
                      </p>
                    </div>
                    {Address?.Logo && (
                      <div>
                        <h5>Current Logo:</h5>
                        <Button
                          onClick={() => window.open(Address.Logo, "_blank")}
                          variant="contained"
                          disabled={isEditMode === false}
                          color="primary"
                          style={{
                            marginRight: "10px",
                            backgroundColor:
                              isEditMode === true ? "#14ca4f" : "#14ca4f2e",
                            color: "white",
                          }}
                        >
                          Preview
                        </Button>
                        <Button
                          onClick={handleDeleteLogo}
                          variant="contained"
                          color="primary"
                          disabled={isEditMode === false}
                          style={{
                            backgroundColor:
                              isEditMode === true ? "#14ca4f" : "#14ca4f2e",
                          }}
                        >
                          Delete Logo
                        </Button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  {renderField(
                    "company_name",
                    "text",
                    "Company Name",
                    !isEditMode
                  )}
                  {renderField("company_phone", "number", "Phone", !isEditMode)}
                  {renderField("company_Email", "email", "Email", !isEditMode)}
                  {renderField(
                    "company_website",
                    "text",
                    "Website",
                    !isEditMode
                  )}
                  {renderField(
                    "company_since",
                    "date",
                    "Established Since",
                    !isEditMode
                  )}
                  {renderField("team_size", "text", "Team Size", !isEditMode)}
                  {renderField("pan_number", "text", "PAN Number", !isEditMode)}
                  {renderField("gst_number", "text", "GST Number", !isEditMode)}
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>Description</label>
                      <Field
                        as="textarea"
                        name="description"
                        className="form-control"
                        style={{
                          backgroundColor:
                            isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                        }}
                        disabled={!isEditMode}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
                {isEditMode && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#14ca4f",
                    }}
                  >
                    Save
                  </Button>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default SectionEmployeeCompanyInfo;
