import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { loadScript } from "../../../../globals/constants";
import CompanyDetails from "../../employer/components/profile/company-details";
import SocialNetwork from "../../employer/components/profile/social-network";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import Address from "../../employer/components/profile/Address";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";

function EmpCompanyProfilePage() {
  const dispatch = useDispatch();
  const empAds = useSelector((state) => state.empAds.empAds);
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const EmpProfile = useSelector((state) => state.EmpDashboard.empProfile);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch]);

  return (
    <>
      <header className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Company Profile!</h2>
            <nav className="breadcrumbs">
              <a href="#">Home</a>
              <a href="#">Dashboard</a>
              <span>Company Profile!</span>
            </nav>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            {empAds?.Profile_Header && empAds?.Profile_Header_Link && (
              <NavLink to={empAds.Profile_Header_Link} target="_blank">
                <img
                  src={empAds.Profile_Header}
                  alt="Banner"
                  className="img-fluid"
                />
              </NavLink>
            )}
          </div>
        </div>
      </header>

      <main>
        <section>
          <CompanyDetails Address={EmpProfile?.Basic} userUUID={userUUID} />
        </section>
        <section>
          <SocialNetwork
            socialNetwork={EmpProfile?.SocialNetwork}
            userUUID={userUUID}
          />
        </section>
        <section>
          <Address Address={EmpProfile?.Address} userUUID={userUUID} />
        </section>
        <section className="col-xl-12 col-lg-12 col-md-12 mb-4">
          {empAds?.Profile_Header && empAds?.Profile_Header_Link && (
            <NavLink to={empAds.Profile_Header_Link} target="_blank">
              <img
                src={empAds.Profile_Header}
                alt="Banner"
                className="img-fluid"
              />
            </NavLink>
          )}
        </section>
      </main>
    </>
  );
}

export default EmpCompanyProfilePage;
