import React, { useEffect, useState } from "react";
import { Tooltip, Snackbar } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Index from "../common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import CircularProgress from "../../../common/loading/Index";
import JobViewPopup from "../../../common/popups/popup-job-view";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import { fetchSavedJobs } from "../../../../reducers/candidate/CanSavedJobsSlice";
import { fetchGeneralAds } from "../../../../reducers/public-user/adsSlice";
import { firestore, db } from "../../../../config/fbConfig";
import { doc, setDoc, deleteDoc, serverTimestamp } from "firebase/firestore";

function CanSavedJobsPage() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [selectedJob, setSelectedJob] = useState(null); // State for selected job
  const loading = useSelector((state) => state.CanDashboard.loading);
  const savedJobsList = useSelector((state) => state.CanSavedJobs);
  const ads = useSelector((state) => state.ads.ads);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const updatedItems = savedJobsList?.CanSavedJobs?.map((item) => {
    const milliseconds = item.CreatedAt?.seconds * 1000;
    const totalMilliseconds =
      milliseconds + item.CreatedAt?.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const newDate = moment(date).format("MMM Do YYYY - hh:mm:A");

    return {
      ...item,
      formattedDate: newDate,
    };
  });

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch, loading, userUUID]);

  useEffect(() => {
    dispatch(fetchSavedJobs(userUUID));
  }, [dispatch, userUUID]);

  useEffect(() => {
    if (updatedItems && updatedItems.length > 0) {
      $(document).ready(function () {
        $("#jobs_bookmark_table").DataTable();
      });
    }
  }, [updatedItems]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralAds());
  }, [dispatch, loading]);

  const handleViewClick = (job) => {
    setSelectedJob(job); // Set the selected job data
  };

  const handleDelete = async (jobId) => {
    try {
      const docRef = doc(firestore, "Users", userUUID, "SavedJobs", jobId);
      await deleteDoc(docRef);
      setOpen(true);
      setMsg("Selected job is Deleted");
      dispatch(fetchSavedJobs(userUUID));
    } catch (error) {
      console.error("Error deleting job: ", error);
    }
  };

  const handleJobApply = async () => {
    if (!selectedJob) {
      setMsg("Selected job is undefined");
      return;
    }
    try {
      const jobData = {
        CandidateId: userUUID,
        Company: selectedJob?.Company || "",
        CreatedAt: serverTimestamp(),
        Designation: selectedJob?.Designation || "",
        District: selectedJob?.District || "",
        Experience: selectedJob?.Experience || "",
        JobDescription: selectedJob?.JobDescription || "",
        JobTitle: selectedJob?.JobTitle || "",
        JobType: selectedJob?.JobType || "",
        Logo: selectedJob?.Logo || "",
        Posts: selectedJob?.Posts || "",
        Specialization: selectedJob?.Specialization || "",
        State: selectedJob?.State || "",
        Status: selectedJob?.Status || "",
        JobId: selectedJob?.id || "",
      };

      const docRef = doc(db, "JobApplied", uuidv4());
      await setDoc(docRef, jobData);
      setOpen(true);
      setMsg("Job Applied Successfully");
    } catch (error) {
      console.error("Error writing document: ", error);
      setMsg(`Error: ${error.message}`);
    }
  };

  return (
    <>
      {savedJobsList && savedJobsList.loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Saved Jobs</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Saved Jobs</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={ads?.SavedJobs_Header_Link} target="_blank">
                  <img
                    src={ads?.SavedJobs_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          <div style={{ marginBottom: "1.5rem" }}>
            <h5>No Of Saved Jobs : {savedJobsList?.CanSavedJobs.length} </h5>
          </div>

          {updatedItems && updatedItems.length > 0 ? (
            <>
              <div className="twm-right-section-panel candidate-save-job site-bg-gray">
                <div className="twm-D_table table-responsive">
                  <table
                    id="jobs_bookmark_table"
                    className="table table-bordered twm-candidate-save-job-list-wrap"
                  >
                    <thead>
                      <tr>
                        <th>Sl No</th>
                        <th>Company </th>
                        <th>Job Title</th>
                        <th>Posted Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {updatedItems?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="twm-candidate-save-job-list">
                              <div className="twm-media">
                                <div className="twm-media-pic">
                                  <img src={item.Logo} alt="#" />
                                </div>
                              </div>
                              <div className="twm-mid-content">
                                <a className="twm-job-title">
                                  <h4>{item.Company}</h4>
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a href="#">{item.JobTitle}</a>
                          </td>
                          <td>
                            <div>{item.formattedDate}</div>
                          </td>
                          <td>
                            <div className="col-6">
                              <div className="twm-table-controls">
                                <ul className="twm-DT-controls-icon list-unstyled d-flex justify-content-end">
                                  <li>
                                    {item.Status === false ? (
                                      <Tooltip
                                        title={"Job is No longer Available"}
                                      >
                                        <span>
                                          <IconButton
                                            disabled
                                            style={{
                                              color: "grey",
                                            }}
                                          >
                                            <RemoveRedEyeIcon
                                              style={{
                                                fontSize: "15px",
                                              }}
                                            />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      <a
                                        data-bs-toggle="modal"
                                        href="#saved-jobs-view"
                                        role="button"
                                        className="custom-toltip"
                                        onClick={() => handleViewClick(item)} // Set selected job on click
                                      >
                                        <Tooltip title={"View Job"}>
                                          <span>
                                            <IconButton>
                                              <RemoveRedEyeIcon
                                                style={{
                                                  fontSize: "15px",
                                                }}
                                              />
                                            </IconButton>
                                          </span>
                                        </Tooltip>
                                      </a>
                                    )}
                                  </li>

                                  <li>
                                    <Tooltip title="Delete Job">
                                      <IconButton
                                        onClick={() => handleDelete(item.Id)}
                                      >
                                        <DeleteIcon
                                          style={{
                                            fontSize: "15px",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Sl No</th>
                        <th>Job Title</th>
                        <th>Company</th>
                        <th>Posted Date</th>
                        <th>Action</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <JobViewPopup
                selectedJob={selectedJob}
                show={false}
                onApply={handleJobApply}
              />

              <Snackbar
                open={open}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                autoHideDuration={2000}
                onClose={handleClose}
                message={msg}
                action={
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              />
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            <NavLink to={ads?.SavedJobs_Footer_Link} target="_blank">
              <img
                src={ads?.SavedJobs_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}

export default CanSavedJobsPage;
