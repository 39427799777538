import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { NavLink, useNavigate } from "react-router-dom";
import { IconButton, Snackbar } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../../../config/fbConfig";
import { publicUser } from "../../../../../../globals/route-names";
import JobViewPopup from "../../../../../common/popups/popup-job-view";
import { fetchFeaturedJobs } from "../../../../../../reducers/public-user/featureJobsSlice";

function SectionAvailableJobsGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.AuthStatus.userRole);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const featuredJobs = useSelector((state) => state.featuredJobs.featuredJobs);
  const [selectedJob, setSelectedJob] = useState(null);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    dispatch(fetchFeaturedJobs());
  }, [dispatch]);

  const updatedItems = featuredJobs?.map((item) => {
    const milliseconds = item.Date.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const formattedDate = moment(date).format("MMM Do YYYY");

    return {
      ...item,
      formattedDate,
    };
  });

  const featured = updatedItems
    ?.filter((item) => item.Page === 2)
    ?.sort((a, b) => a.Priority - b.Priority);

  const handleViewClick = (job) => {
    setSelectedJob(job);
  };

  const handleJobApply = async (data) => {
    if (!data) {
      navigate(publicUser.pages.LOGIN);
      return;
    }
    if (!selectedJob) {
      setMsg("Selected job is undefined");
      return;
    }
    try {
      const jobData = {
        CandidateId: userUUID,
        Company: selectedJob.Company || "",
        Designation: selectedJob.Designation || "",
        District: selectedJob.District || "",
        Experience: selectedJob.Experience || "",
        JobDescription: selectedJob.JobDescription || "",
        JobTitle: selectedJob.JobTitle || "",
        JobType: selectedJob.JobType || "",
        Logo: selectedJob.Logo || "",
        Posts: selectedJob.Posts || "",
        Specialization: selectedJob.Specialization || "",
        State: selectedJob.State || "",
        Status: selectedJob.Status || "",
        JobId: selectedJob.id || "",
      };
      const docRef = doc(db, "JobApplied", uuidv4());
      await setDoc(docRef, jobData);
      setOpen(true);
      setMsg("Job Applied Successfully");
    } catch (error) {
      console.error("Error writing document: ", error);
      setMsg(`Error: ${error.message}`);
    }
  };

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  return (
    <>
      <h4 className="twm-s-title">Featured Jobs</h4>
      <div className="twm-jobs-list-wrap">
        <div className="row">
          {featured?.map((item, index) => (
            <div key={index} className="col-lg-6 col-md-6 m-b30">
              <div className="hpage-6-featured-block">
                <div className="inner-content">
                  <div className="top-content">
                    <span className="job-time">
                      {item.JobType === 0 ? "Full-Time" : "Part-Time"}
                    </span>
                    <span className="job-post-time">{item.formattedDate}</span>
                  </div>
                  <div className="mid-content">
                    <div className="company-logo">
                      <img
                        src={item.Logo}
                        alt={item.Company}
                        style={{
                          borderRadius: "50%",
                          height: "50px",
                          width: "50px",
                        }}
                      />
                    </div>
                    <div className="company-info">
                      <h4>{item.Company}</h4>
                      <p className="company-address">
                        {item.District}, {item.State}
                      </p>
                    </div>
                  </div>
                  <div className="bottom-content">
                    <h4 className="job-name-title">{item.JobTitle}</h4>
                    <h5>No of Posts: {item.Posts}</h5>
                  </div>
                  <div className="aply-btn-area">
                    <a
                      data-bs-toggle="modal"
                      href="#saved-jobs-view"
                      role="button"
                      className="custom-toltip aplybtn"
                      onClick={() => handleViewClick(item)}
                    >
                      <i className="fa fa-check" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <JobViewPopup
        selectedJob={selectedJob}
        show={false}
        userRole={userRole}
        onApply={handleJobApply}
      />

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msg}
        action={
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}

export default SectionAvailableJobsGrid;
