import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import { Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../../config/fbConfig";

const validationSchema = Yup.object().shape({
  can_whatsapp: Yup.string()
    .required("Whatsapp is required")
    .max(10, "Whatsapp number must be at most 10 characters")
    .matches(/^\d+$/, "Whatsapp number must contain only numbers"),
  can_twitter: Yup.string(),
  can_instagram: Yup.string(),
  can_facebook: Yup.string(),
  can_alternate_phone: Yup.string()
    .max(10, "Alternate Phone number must be at most 10 characters")
    .matches(/^\d+$/, "Whatsapp number must contain only numbers"),
});

const SectionEmployeeSocialInfo = ({ socialNetwork, userUUID }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false, msg: "" });

  const toggleEditMode = () => setIsEditMode((prev) => !prev);

  const handleCloseSnackbar = (event, reason) => {
    if (reason !== "clickaway") {
      setSnackbarState({ ...snackbarState, open: false });
    }
  };

  const handleFormSubmit = async (values) => {
    const data = {
      SocialNetwork: {
        Whatsapp: values.can_whatsapp,
        X: values.can_twitter,
        Instagram: values.can_instagram,
        Facebook: values.can_facebook,
        AlternatePhone: values.can_alternate_phone,
      },
    };

    try {
      const docRef = doc(db, "Verify", userUUID);
      await setDoc(docRef, data, { merge: true });
      setSnackbarState({ open: true, msg: "Saved successfully" });
      setIsEditMode(false);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  return (
    <Formik
      initialValues={{
        can_whatsapp: socialNetwork?.Whatsapp || "",
        can_twitter: socialNetwork?.X || "",
        can_instagram: socialNetwork?.Instagram || "",
        can_facebook: socialNetwork?.Facebook || "",
        can_alternate_phone: socialNetwork?.AlternatePhone || "",
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleFormSubmit}
    >
      {({ values }) => (
        <Form>
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-tittle m-a0">
                Social Network &nbsp; ( &nbsp;
                <span
                  style={{
                    color:
                      socialNetwork?.Status === "Not Updated"
                        ? "red"
                        : socialNetwork?.Status === "Not Verified"
                          ? "blue"
                          : "green",
                  }}
                >
                  {socialNetwork?.Status}
                </span>
                &nbsp; )
              </h4>
            </div>
            <div className="edit-button-end">
              <Tooltip title={isEditMode ? "Close" : "Edit"}>
                <IconButton onClick={toggleEditMode}>
                  {isEditMode ? (
                    <CloseIcon fontSize="small" />
                  ) : (
                    <EditIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <div className="panel-body wt-panel-body p-a20 m-b30">
              <div className="row">
                {[
                  {
                    label: "Whatsapp",
                    name: "can_whatsapp",
                    placeholder: "Whatsapp",
                    type: "number",
                  },
                  {
                    label: "X",
                    name: "can_twitter",
                    placeholder: "Twitter handle",
                    type: "text",
                  },
                  {
                    label: "Instagram",
                    name: "can_instagram",
                    placeholder: "Instagram username",
                    type: "text",
                  },
                  {
                    label: "Facebook",
                    name: "can_facebook",
                    placeholder: "Facebook profile",
                    type: "text",
                  },
                  {
                    label: "Alternate phone number",
                    name: "can_alternate_phone",
                    placeholder: "Alternate phone number",
                    type: "number",
                  },
                ].map(({ label, name, placeholder, type }) => (
                  <div key={name} className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>{label}</label>
                      <Field
                        className="form-control"
                        name={name}
                        type={type}
                        placeholder={placeholder}
                        value={values[name]}
                        style={{
                          backgroundColor:
                            isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                        }}
                        disabled={!isEditMode}
                      />
                      <ErrorMessage
                        component="div"
                        name={name}
                        className="error"
                        style={{ color: "red" }}
                      />
                    </div>
                  </div>
                ))}
                <div className="col-lg-12 col-md-12">
                  <div className="text-left">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Snackbar
            open={snackbarState.open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message={snackbarState.msg}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default SectionEmployeeSocialInfo;
