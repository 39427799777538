import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import {
  empRoute,
  employer,
  pubRoute,
} from "../../../../../globals/route-names";
import { useNavigate } from "react-router-dom";

function SectionPricingMonthly() {
  const navigate = useNavigate();
  return (
    <>
      <div className="pricing-block-outer">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 m-b30">
            <div className="pricing-table-1">
              <div className="p-table-title">
                <h4 className="wt-title">Basic</h4>
              </div>
              <div className="p-table-inner">
                <div className="p-table-price">
                  <span
                    style={{ textDecoration: "line-through", fontSize: "30px" }}
                  >
                    ₹2999/
                  </span>
                  <span style={{ fontSize: "30px" }}>free</span>
                </div>
                <div className="p-table-list">
                  <ul>
                    <li>
                      <i className="feather-check" />1 job posting
                    </li>

                    <li>
                      <i className="feather-check" />
                      job displayed fo 30 days
                    </li>
                    <li>
                      <i className="feather-check" />
                      Database access
                    </li>
                    <li className="disable">
                      <i className="feather-x" />
                      Premium support
                    </li>
                  </ul>
                </div>
                <div
                  className="p-table-btn"
                  onClick={() => {
                    navigate(`${empRoute(employer.CART)}/${0}`);
                  }}
                >
                  <p className="site-button">Purchase Now</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-table-highlight m-b30">
            <div className="pricing-table-1 circle-yellow">
              <div className="p-table-title">
                <h4 className="wt-title">Standard</h4>
              </div>
              <div className="p-table-inner">
                <div className="p-table-price">
                  <span
                    style={{ fontSize: "30px", textDecoration: "line-through" }}
                  >
                    ₹4999/
                  </span>
                  <span style={{ fontSize: "30px" }}>free</span>
                </div>
                <div className="p-table-list">
                  <ul>
                    <li>
                      <i className="feather-check" />3 job posting
                    </li>
                    <li>
                      <i className="feather-check" />
                      job displayed for 30 days
                    </li>
                    <li>
                      <i className="feather-check" />
                      Database access
                    </li>

                    <li>
                      <i className="feather-check" />
                      Premium support
                    </li>
                  </ul>
                </div>
                <div
                  className="p-table-btn"
                  onClick={() => {
                    navigate(`${empRoute(employer.CART)}/${4999}`);
                  }}
                >
                  <p className="site-button">Purchase Now</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-table-highlight m-b30">
            <div className="pricing-table-1 circle-green">
              <div className="p-table-recommended">Recommended</div>
              <div className="p-table-title">
                <h4 className="wt-title">Advanced</h4>
              </div>
              <div className="p-table-inner">
                <div className="p-table-price">
                  <span
                    style={{ fontSize: "30px", textDecoration: "line-through" }}
                  >
                    ₹7999/
                  </span>
                  <span style={{ fontSize: "30px" }}>free</span>
                </div>
                <div className="p-table-list">
                  <ul>
                    <li>
                      <i className="feather-check" />5 job posting
                    </li>
                    <li>
                      <i className="feather-check" />
                      job displayed for 30 days
                    </li>
                    <li>
                      <i className="feather-check" />
                      Database access
                    </li>

                    <li>
                      <i className="feather-check" />
                      Premium support
                    </li>
                  </ul>
                </div>
                <div
                  className="p-table-btn"
                  onClick={() => {
                    navigate(`${empRoute(employer.CART)}/${7999}`);
                  }}
                >
                  <p className="site-button">Purchase Now</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-table-highlight m-b30">
            <div className="pricing-table-1">
              <div className="p-table-title">
                <h4 className="wt-title">Premium</h4>
              </div>
              <div className="p-table-inner">
                <div className="p-table-price">
                  <span
                    style={{ fontSize: "30px", textDecoration: "line-through" }}
                  >
                    ₹9999/
                  </span>
                  <span style={{ fontSize: "30px" }}>free</span>
                </div>
                <div className="p-table-list">
                  <ul>
                    <li>
                      <i className="feather-check" />7 job posting
                    </li>
                    <li>
                      <i className="feather-check" />
                      job displayed for 30 days
                    </li>
                    <li>
                      <i className="feather-check" />
                      Database access
                    </li>

                    <li>
                      <i className="feather-check" />
                      Premium support
                    </li>
                  </ul>
                </div>
                <div
                  className="p-table-btn"
                  onClick={() => {
                    navigate(`${empRoute(employer.CART)}/${9999}`);
                  }}
                >
                  <p className="site-button">Purchase Now</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-table-highlight m-b30">
            <div className="pricing-table-1 circle-yellow">
              <div className="p-table-title">
                <h4 className="wt-title">Elite</h4>
              </div>
              <div className="p-table-inner">
                <div className="p-table-price">
                  <span
                    style={{ fontSize: "30px", textDecoration: "line-through" }}
                  >
                    ₹14999/
                  </span>
                  <span style={{ fontSize: "30px" }}>free</span>
                </div>
                <div className="p-table-list">
                  <ul>
                    <li>
                      <i className="feather-check" />9 job posting
                    </li>
                    <li>
                      <i className="feather-check" />
                      job displayed for 30 days
                    </li>
                    <li>
                      <i className="feather-check" />
                      Database access
                    </li>

                    <li>
                      <i className="feather-check" />
                      Premium support
                    </li>
                  </ul>
                </div>
                <div
                  className="p-table-btn"
                  onClick={() => {
                    navigate(`${empRoute(employer.CART)}/${14999}`);
                  }}
                >
                  <p className="site-button">Purchase Now</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-table-highlight m-b30">
            <div className="pricing-table-1 circle-green">
              <div className="p-table-title">
                <h4 className="wt-title">Contract Plan</h4>
              </div>
              <div className="p-table-inner">
                <div className="p-table-price">
                  <span
                    style={{ fontSize: "30px", textDecoration: "line-through" }}
                  >
                    Custom/
                  </span>
                  <span style={{ fontSize: "30px" }}>free</span>
                </div>
                <div className="p-table-list" style={{ minHeight: "193px" }}>
                  <ul>
                    <li>
                      <i className="feather-check" />
                      Bulk job posting
                    </li>
                    <li>
                      <i className="feather-check" />
                      job displayed for 30 days
                    </li>
                    <li>
                      <i className="feather-check" />
                      Bulk Database access
                    </li>

                    <li>
                      <i className="feather-check" />
                      Premium support
                    </li>
                  </ul>
                </div>
                <div className="p-table-btn">
                  <NavLink
                    to={publicUser.pages.CONTACT}
                    className="site-button"
                  >
                    Contact Us
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionPricingMonthly;
