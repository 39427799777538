import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Button, Alert, Stack } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Index from "../../candidate/common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpNotification } from "../../../../reducers/employer/EmpNotificationSlice";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";

function EmpNotificationPage() {
  // Load custom script on component mount
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const [seenNotifications, setSeenNotifications] = useState(() => {
    const storedNotifications = localStorage.getItem("seenNotifications");
    return storedNotifications ? JSON.parse(storedNotifications) : [];
  });
  const EmpNotification = useSelector(
    (state) => state.EmpNotification.EmpNotification
  );

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpNotification(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch]);

  return (
    <>
      {EmpNotification.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Notifications </h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Notifications</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Noti_Header_Link} target="_blank">
                  <img
                    src={empAds?.Noti_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {EmpNotification?.length > 0 ? (
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              {EmpNotification?.map((item, index) => {
                const isSeen = seenNotifications.includes(index);
                return (
                  <Grid key={index} item xs={12}>
                    <NavLink to={item.Link} target="_blank">
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert
                          icon={<NotificationsIcon fontSize="inherit" />}
                          severity={item.Read === true ? "success" : "info"}
                          action={
                            <Button
                              // onClick={() => handleNotificationClose(index)}
                              color="inherit"
                              size="small"
                            >
                              <Typography
                                style={{
                                  fontWeight: item.Read === true ? "0" : "550",
                                  fontSize: "14px",
                                  fontFamily: "Rubik",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item.Author}
                              </Typography>
                            </Button>
                          }
                          sx={{
                            color: item.Read === true ? "grey" : "black",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: item.Read === true ? "0" : "550",
                              fontSize: "15px",
                              fontFamily: "Rubik",
                            }}
                          >
                            {item.Title}
                          </Typography>
                          <Typography
                            style={{ fontSize: "14px", fontFamily: "Rubik" }}
                          >
                            {item.Description}
                          </Typography>
                        </Alert>
                      </Stack>
                    </NavLink>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            <NavLink to={empAds?.Noti_Footer_Link} target="_blank">
              <img
                src={empAds?.Noti_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}

export default EmpNotificationPage;
