import { createSlice } from "@reduxjs/toolkit";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../config/fbConfig"; // Adjust the import according to your project structure

// Initial State
const initialState = {
  CanDeals: [],
  loading: false,
  error: null,
};

// Slice
const CanDealsSlice = createSlice({
  name: "CanDeals",
  initialState,
  reducers: {
    setCanDealsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setCanDealsSuccess: (state, action) => {
      state.loading = false;
      state.CanDeals = action.payload;
    },
    setCanDealsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setCanDealsStart, setCanDealsSuccess, setCanDealsFailure } =
  CanDealsSlice.actions;
export default CanDealsSlice.reducer;

export const fetchCanDeals = () => (dispatch) => {
  try {
    dispatch(setCanDealsStart());
    const docRef = doc(db, "Public", "EmployeeDeals");
    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const docData = docSnapshot.data();
          if (docData) {
            const fetchedData = Object.entries(docData).map(([key, value]) => ({
              Id: key,
              ...value,
            }));
            dispatch(setCanDealsSuccess(fetchedData));
          } else {
            dispatch(setCanDealsSuccess([]));
          }
        } else {
          dispatch(setCanDealsFailure("No such document!"));
        }
      },
      (error) => {
        dispatch(setCanDealsFailure(error.message));
      }
    );
    return unsubscribe;
  } catch (error) {
    console.error("Error setting up snapshot listener:", error);
    dispatch(setCanDealsFailure(error.message));
  }
};
