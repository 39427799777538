import React, { useEffect, useState } from "react";
import {
  IconButton,
  Tooltip,
  Button,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../../../../config/fbConfig";


// Validation schema
const validationSchema = Yup.object().shape({
  resume: Yup.mixed()
    .required("Resume is required")
    .test(
      "fileSize",
      "File size is too large. Maximum size is 3MB.",
      (value) => !value || (value && value.size <= 3 * 1024 * 1024)
    )
    .test(
      "fileFormat",
      "Unsupported format. Only PDF and PNG are allowed.",
      (value) =>
        !value ||
        (value && ["application/pdf", "image/png"].includes(value.type))
    ),
});

const SectionCandidateResume = ({ resumeURL, userUUID }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false, msg: "" });
  const [previewUrl, setPreviewUrl] = useState(resumeURL?.ResumeURL);
  const [isLoading, setIsLoading] = useState(false);

  const toggleEditMode = () => setIsEditMode((prev) => !prev);

  const handleCloseSnackbar = (event, reason) => {
    if (reason !== "clickaway") {
      setSnackbarState({ ...snackbarState, open: false });
    }
  };

  useEffect(() => {
    if (resumeURL?.ResumeURL) {
      setPreviewUrl(resumeURL.ResumeURL);
    }
  }, [resumeURL]);

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    try {
      let uploadedResumeURL = resumeURL?.ResumeURL;
      if (values.resume) {
        const fileName = `${new Date().toISOString().split("T")[0]
          }_${userUUID}`;
        const storageRef = ref(
          storage,
          `resumes/${userUUID}/resume-${fileName}`
        );
        await uploadBytes(storageRef, values.resume);
        uploadedResumeURL = await getDownloadURL(storageRef);
      }

      const data = { Resume: { ResumeURL: uploadedResumeURL } };
      const docRef = doc(db, "Verify", userUUID);
      await setDoc(docRef, data, { merge: true });

      setSnackbarState({ open: true, msg: "Resume uploaded successfully" });
      setIsEditMode(false);
    } catch (error) {
      console.error("Error uploading resume: ", error);
      setSnackbarState({ open: true, msg: "Failed to upload resume" });
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ resume: null }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleFormSubmit}
    >
      {({ setFieldValue, isSubmitting }) => (
        <Form>
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-title m-a0">
                Resume Upload &nbsp; (&nbsp;
                <span
                  style={{
                    color:
                      resumeURL?.Status === "Not Updated"
                        ? "red"
                        : resumeURL?.Status === "Not Verified"
                          ? "blue"
                          : "green",
                  }}
                >
                  {resumeURL?.Status}
                </span>
                &nbsp; )
              </h4>
            </div>
            <div className="edit-button-end">
              <Tooltip title={isEditMode ? "Close" : "Edit"}>
                <IconButton onClick={toggleEditMode}>
                  {isEditMode ? (
                    <CloseIcon fontSize="small" />
                  ) : (
                    <EditIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <div className="panel-body wt-panel-body p-a20 m-b30">
              <div className="form-group">
                <label>Upload Resume</label>
                {isEditMode && (
                  <input
                    type="file"
                    name="resume"
                    accept=".pdf,.png"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFieldValue("resume", file);
                      if (file) {
                        const url = URL.createObjectURL(file);
                        setPreviewUrl(url);
                      }
                    }}
                    className="form-control"
                    style={{
                      backgroundColor: isEditMode ? "#14ca4f54" : "#14ca4f2e",
                    }}
                  />
                )}
                <ErrorMessage
                  name="resume"
                  component="div"
                  className="error-message"
                  style={{ color: "red" }}
                />
              </div>

              {resumeURL?.ResumeURL && (
                <div className="text-left mt-3">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(previewUrl, "_blank")}
                    style={{
                      backgroundColor: isEditMode ? "#14ca4f" : "#14ca4f2e",
                    }}
                  >
                    Preview Resume
                  </Button>
                </div>
              )}

              <div className="text-left mt-3">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isEditMode || isSubmitting || isLoading}
                  style={{
                    backgroundColor: isEditMode ? "#14ca4f" : "#14ca4f2e",
                  }}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Save Changes"}
                </Button>
              </div>
            </div>
          </div>
          <Snackbar
            open={snackbarState.open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message={snackbarState.msg}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default SectionCandidateResume;
