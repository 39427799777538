import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { collection, query, where, getDocs } from "firebase/firestore";
import SectionSideAdvert from "./section-side-advert";
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import { locationOptions } from "../../../../../common/static-data";
import { firestore, db } from "../../../../../../config/fbConfig";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

function SectionJobsSidebar1({
  Datas,
  loadings,
  selectedDesignation,
  selectedSpecialization,
  selectedLocation,
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [specializationOptions, setSpecializationOptions] = useState([]);

  const collectionName = "Public";
  const docName = "Designation";

  useEffect(() => {
    Datas(data);
    loadings(loading);
  }, [data, loading, Datas, loadings]);

  const formik = useFormik({
    initialValues: {
      designation: selectedDesignation || "All Designation",
      specialization: selectedSpecialization || "All Designation",
      location: selectedLocation || "All Locations",
      fullTime: false,
      partTime: false,
    },
    validationSchema: Yup.object({
      designation: Yup.string().required("Designation is required"),
      specialization: Yup.string().required("Specialization is required"),
      location: Yup.string().required("Location is required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setLoading(true);
      window.scrollTo({ top: 0 });
      const filters = [];
      if (values.designation !== "All Designation")
        filters.push(where("Designation", "==", values.designation));
      if (values.specialization !== "All Specialization")
        filters.push(where("Specialization", "==", values.specialization));
      if (values.location !== "All Locations")
        filters.push(where("District", "==", values.location));
      if (values.fullTime || values.partTime) {
        const jobTypes = [];
        if (values.fullTime) jobTypes.push(0);
        if (values.partTime) jobTypes.push(1);
        filters.push(where("JobType", "in", jobTypes));
      }

      const q = filters.length
        ? query(collection(firestore, "Jobs"), ...filters)
        : collection(firestore, "Jobs");
      const querySnapshot = await getDocs(q);

      const newData = querySnapshot.empty
        ? []
        : querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      setData(newData);
      setLoading(false);
    },
  });

  const handleReset = () => {
    window.scrollTo({ top: 0 });
    formik.resetForm();
    formik.handleSubmit();
  };

  useEffect(() => {
    formik.setValues({
      designation: selectedDesignation || "All Designation",
      specialization: selectedSpecialization || "All Specialization",
      location: selectedLocation || "All Locations",
      fullTime: false,
      partTime: false,
    });
  }, [selectedDesignation, selectedSpecialization, selectedLocation]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, collectionName, docName),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const docData = docSnapshot.data();
          const fetchedData = Object.entries(docData).map(([key, value]) => ({
            Id: key,
            label: value.Tag,
          }));
          setDesignationOptions([
            { Id: "alldesignation", label: "All Designation" },
            ...fetchedData,
          ]);
        }
      }
    );
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "Public", "Specialization"),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const docData = docSnapshot.data();
          const fetchedData = Object.entries(docData).map(([key, value]) => ({
            Id: key,
            label: value.Tag,
          }));
          setSpecializationOptions([
            { Id: "allspecialization", label: "All Specialization" },
            ...fetchedData,
          ]);
        }
      }
    );
    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className="side-bar">
        <div className="sidebar-elements search-bx">
          <form onSubmit={formik.handleSubmit}>
            {[
              {
                name: "designation",
                label: "Designation",
                options: designationOptions,
              },
              {
                name: "specialization",
                label: "Specialization",
                options: specializationOptions,
              },
              { name: "location", label: "Location", options: locationOptions },
            ].map(({ name, label, options }) => (
              <div key={name} className="form-group mb-4">
                <label
                  className="section-head-small mb-4"
                  htmlFor={`${name}-select`}
                >
                  {label}
                </label>
                <FormControl fullWidth>
                  <InputLabel id={`${name}-label`}>{label}</InputLabel>
                  <Select
                    labelId={`${name}-label`}
                    id={`${name}-select`}
                    size="small"
                    value={formik.values[name]}
                    label={label}
                    onChange={(e) => formik.setFieldValue(name, e.target.value)} // Ensure Formik handles the change
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      Select {label}
                    </MenuItem>
                    {options?.map((option, index) => (
                      <MenuItem key={index} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {formik.errors[name] && formik.touched[name] && (
                  <div className="error">{formik.errors[name]}</div>
                )}
              </div>
            ))}
            <div className="twm-sidebar-ele-filter">
              <label className="section-head-small mb-4">Job Type</label>
              <ul>
                {[
                  { name: "fullTime", label: "Full Time" },
                  { name: "partTime", label: "Part Time" },
                ].map(({ name, label }) => (
                  <li key={name}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={name}
                        name={name}
                        checked={formik.values[name]}
                        onChange={formik.handleChange}
                      />
                      <label className="form-check-label" htmlFor={name}>
                        {label}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <button
              type="submit"
              className="site-button"
              style={{ width: "100%", padding: "8px" }}
            >
              Search
            </button>
          </form>
        </div>
      </div>
      <SectionSideAdvert />
    </>
  );
}

export default SectionJobsSidebar1;
