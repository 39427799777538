import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import SectionFaqGeneral from "../../public-user/sections/faq/section-faq-general";
import SectionFaqJobs from "../../public-user/sections/faq/section-faq-jobs";
import SectionFaqPayments from "../../public-user/sections/faq/section-faq-payment";
import SectionFaqReturn from "../../public-user/sections/faq/section-faq-return";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";

const EmpFaqPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch]);

  return (
    <>
      {/*Headline Display Start*/}
      <div className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>FAQ</h2>
            <div className="breadcrumbs">
              <a>Home</a>
              <a>Dashboard</a>
              <span>FAQ</span>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            <NavLink to={empAds?.FAQ_Header_Link} target="_blank">
              <img
                src={empAds?.FAQ_Header}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      </div>
      {/*Headline Display End*/}
      <div className="panel panel-default site-bg-white m-t30">
        {/*FAQ Section Display Start*/}
        <div className="panel-heading p-a20">
          <h4 className="panel-tittle m-a0">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  data-bs-toggle="tab"
                  data-bs-target="#General"
                  type="button"
                  role="tab"
                  style={{ color: "#39ce6d" }}
                >
                  Health Professionals
                </button>
              </li> */}
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#Jobs"
                  type="button"
                  role="tab"
                  aria-controls="Jobs"
                  style={{ color: "#39ce6d" }}
                >
                  Business
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#Payment"
                  type="button"
                  role="tab"
                  aria-controls="Payment"
                  style={{ color: "#39ce6d" }}
                >
                  Employers
                </button>
              </li>
              {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#Return"
                  type="button"
                  role="tab"
                  aria-controls="Return"
                  style={{ color: "#39ce6d" }}
                >
                  Return
                </button>
              </li> */}
            </ul>
          </h4>
        </div>
        {/*FAQ Section Display End*/}

        {/*FAQ Display Start*/}
        <div className="twm-tabs-style-1 center p-t30">
          <div className="tab-content" id="myTabContent">
            {/*Tabs content one*/}
            <div
              className="tab-pane fade show active"
              id="General"
              role="tabpanel"
            >
              <SectionFaqGeneral />
            </div>
            {/*Tabs content two*/}
            <div className="tab-pane fade" id="Jobs" role="tabpanel">
              <SectionFaqJobs />
            </div>
            {/*Tabs content three*/}
            <div className="tab-pane fade" id="Payment" role="tabpanel">
              <SectionFaqPayments />
            </div>
            {/*Tabs content Four*/}
            <div className="tab-pane fade" id="Return" role="tabpanel">
              <SectionFaqReturn />
            </div>
          </div>
        </div>
        {/*FAQ Display End*/}
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
          <NavLink to={empAds?.FAQ_Footer_Link} target="_blank">
            <img
              src={empAds?.FAQ_Footer}
              alt="Banner Image"
              className="img-fluid"
            />
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default EmpFaqPage;
