import React, { useState, useEffect } from "react";
import {
  IconButton,
  Tooltip,
  Snackbar,
  Button,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../../../../config/fbConfig";

const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB in bytes
const today = new Date();
const todayString = today.toISOString().split("T")[0];
const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  DOB: Yup.date()
    .required("DOB is required")
    .max(today, "DOB cannot be in the future"),
  fatherName: Yup.string().required("Father's Name is required"),
  gender: Yup.string().required("Gender is required"),
  aadharNumber: Yup.string()
    .matches(/^\d{12}$/, "Invalid Aadhar number")
    .required("Aadhar number is required"),
  file: Yup.mixed()
    .nullable()
    .test("fileRequired", "File is required", function (value) {
      const { createError } = this;
      if (!value && !this.parent.Aadhar && this.parent.isEditMode) {
        return createError({ path: "file", message: "File is required" });
      }
      return true;
    })
    .test("fileSize", "File size must be less than 3MB", (value) => {
      return !value || value.size <= MAX_FILE_SIZE;
    })
    .test("fileType", "Unsupported file type", (value) => {
      return (
        !value ||
        value.type === "application/pdf" ||
        value.type === "image/png" ||
        value.type === "image/jpeg"
      );
    }),
});

function SectionCandidateBasicInfo({ profileData, userUUID }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [fileUrl, setFileUrl] = useState(profileData?.Aadhar);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (profileData?.Aadhar) {
      setFileUrl(profileData.Aadhar);
    }
  }, [profileData]);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    try {
      let uploadedFileURL = fileUrl; // Retain old URL if no new file
      if (values.file) {
        const fileName = `${todayString}_${userUUID}`;
        const storageRef = ref(storage, `users/${userUUID}/aadhar-${fileName}`);
        await uploadBytes(storageRef, values.file);
        uploadedFileURL = await getDownloadURL(storageRef);
        setFileUrl(uploadedFileURL); // Update the preview URL after upload
      }

      const data = {
        Basic: {
          FirstName: values.firstName,
          LastName: values.lastName,
          DOB: values.DOB,
          FatherName: values.fatherName,
          Gender: values.gender,
          Aadhar: uploadedFileURL,
          AadharNumber: values.aadharNumber,
        },
      };

      await setDoc(doc(db, "Verify", userUUID), data, { merge: true });
      setMsg("Saved successfully");
      setOpen(true);
      setIsEditMode(false);
    } catch (error) {
      console.error("Error writing document: ", error);
      setMsg("Error saving data");
      setOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: profileData?.FirstName || "",
        lastName: profileData?.LastName || "",
        DOB: profileData?.DOB || "",
        fatherName: profileData?.FatherName || "",
        gender: profileData?.Gender || "",
        gstNumber: profileData?.GSTNumber || "",
        panNumber: profileData?.PANNumber || "",
        aadharNumber: profileData?.AadharNumber || "",
        file: null,
        Aadhar: profileData?.Aadhar || "",
        isEditMode: isEditMode, // Track edit mode
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleFormSubmit}
    >
      {({ setFieldValue, errors, touched, values }) => (
        <Form>
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-title m-a0">
                Basic Information &nbsp; (
                <span
                  style={{
                    color:
                      profileData?.Status === "Not Updated"
                        ? "red"
                        : profileData?.Status === "Not Verified"
                        ? "blue"
                        : "green",
                  }}
                >
                  {profileData?.Status}
                </span>
                )
              </h4>
            </div>
            <div className="edit-button-end">
              <Tooltip title={isEditMode ? "Close" : "Edit"}>
                <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                  {isEditMode ? (
                    <CloseIcon fontSize="small" />
                  ) : (
                    <EditIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <div className="panel-body wt-panel-body p-a20 m-b30">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>First Name</label>
                    <Field
                      className="form-control"
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      style={{
                        backgroundColor: isEditMode ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Last Name</label>
                    <Field
                      className="form-control"
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      style={{
                        backgroundColor: isEditMode ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>DOB</label>
                    <Field
                      className="form-control"
                      name="DOB"
                      type="date"
                      disabled={!isEditMode}
                      style={{
                        backgroundColor: isEditMode ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      max={todayString}
                    />
                    <ErrorMessage
                      name="DOB"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Father's Name</label>
                    <Field
                      className="form-control"
                      name="fatherName"
                      type="text"
                      placeholder="Father's Name"
                      style={{
                        backgroundColor: isEditMode ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="fatherName"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Gender</label>
                    <Field
                      as="select"
                      className="form-control"
                      name="gender"
                      style={{
                        backgroundColor: isEditMode ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    >
                      <option value="" disabled>
                        Select Gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </Field>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Aadhar Number</label>
                    <Field
                      className="form-control"
                      name="aadharNumber"
                      type="text"
                      placeholder="Aadhar Number"
                      style={{
                        backgroundColor: isEditMode ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="aadharNumber"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Aadhar File</label>
                    <Field
                      className="form-control"
                      name="file"
                      type="file"
                      style={{
                        backgroundColor: isEditMode ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                    />
                    <ErrorMessage
                      name="file"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                    {fileUrl && (
                      <Button
                        disabled={isEditMode === false}
                        variant="contained"
                        style={{
                          marginTop: "1rem",
                          backgroundColor: isEditMode ? "#14ca4f" : "#14ca4f2e",
                          color: "white",
                        }}
                      >
                        <a
                          href={fileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "white" }}
                        >
                          View current Aadhar document
                        </a>
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              {isEditMode && (
                <div className="text-left">
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                    style={{
                      marginTop: "2rem",
                      backgroundColor: isEditMode ? "#14ca4f" : "#14ca4f2e",
                      color: "white",
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={msg}
          />
        </Form>
      )}
    </Formik>
  );
}

export default SectionCandidateBasicInfo;
