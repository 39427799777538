import React, { useEffect, useState } from "react";
import { IconButton, Tooltip, Snackbar, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { doc, deleteDoc } from "firebase/firestore";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { firestore } from "../../../../config/fbConfig";
import Index from "../../candidate/common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import JobViewPopup from "../../../common/popups/popup-jobs-emp";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpBookmark } from "../../../../reducers/employer/EmpBookmarkSlice";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";

function EmpBookmarksPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const bookmarkResumeList = useSelector((state) => state.EmpBookmark);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const [selectedJob, setSelectedJob] = useState(null); // State for selected job

  const handleViewClick = (job) => {
    setSelectedJob(job); // Set the selected job data
  };

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  const handleDelete = async (jobId) => {
    try {
      const docRef = doc(firestore, "Users", userUUID, "BookmarkResume", jobId);
      await deleteDoc(docRef);
      setOpen(true);
      setMsg("Selected Candidate is Deleted");
      dispatch(fetchEmpBookmark(userUUID));
    } catch (error) {
      console.error("Error deleting job: ", error);
    }
  };

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpBookmark(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch]);

  return (
    <>
      {bookmarkResumeList.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Bookmark</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Bookmarked Resumes</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Bookmark_Header_Link} target="_blank">
                  <img
                    src={empAds?.Bookmark_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>
          <div>
            {bookmarkResumeList?.EmpBookmark.length > 0 ? (
              <div className="twm-pro-view-chart-wrap">
                <div className="col-lg-12 col-md-12 mb-4">
                  <div className="panel panel-default site-bg-white m-t30">
                    <div className="panel-heading wt-panel-heading p-a20">
                      <h4 className="panel-tittle m-a0">
                        <i className="far fa-bookmark" />
                        Bookmarked Resumes
                      </h4>
                    </div>
                    <div className="panel-body wt-panel-body">
                      <div className="twm-D_table p-a20 table-responsive">
                        <table
                          id="candidate_data_table"
                          className="table table-bordered"
                        >
                          <thead>
                            <tr>
                              <th>Sl.No</th>
                              <th>Name</th>
                              <th>Designation</th>
                              <th>Specialization</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {bookmarkResumeList?.EmpBookmark?.map(
                              (item, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <div className="twm-DT-candidates-list">
                                      <div className="twm-mid-content">
                                        <a href="#" className="twm-job-title">
                                          <h4>{item.Name}</h4>
                                          <p className="twm-candidate-address">
                                            {item.District}, {item.State},
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.Designation}</td>
                                  <td>{item.Specialization}</td>
                                  <td>
                                    <div className="twm-table-controls">
                                      <ul className="twm-DT-controls-icon list-unstyled d-flex justify-content-end">
                                        <li style={{ marginRight: "12px" }}>
                                          <a
                                            data-bs-toggle="modal"
                                            href="#saved-jobs-view"
                                            role="button"
                                            className="custom-toltip"
                                            onClick={() =>
                                              handleViewClick(item)
                                            }
                                          >
                                            <Tooltip title={"View Candidate"}>
                                              <span>
                                                <IconButton>
                                                  <RemoveRedEyeIcon
                                                    style={{
                                                      fontSize: "15px",
                                                    }}
                                                  />
                                                </IconButton>
                                              </span>
                                            </Tooltip>
                                          </a>
                                        </li>
                                        <li>
                                          <Tooltip title="Delete">
                                            <IconButton
                                              onClick={() =>
                                                handleDelete(item.id)
                                              }
                                            >
                                              <DeleteIcon
                                                style={{
                                                  fontSize: "15px",
                                                }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th />
                              <th>Name</th>
                              <th>Designation</th>
                              <th>Specialization</th>
                              <th>Action</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Index />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-4">
              <NavLink to={empAds?.Bookmark_Footer_Link} target="_blank">
                <img
                  src={empAds?.Bookmark_Footer}
                  alt="Banner Image"
                  className="img-fluid"
                />
              </NavLink>
            </div>
          </div>
        </>
      )}

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={2000}
        onClose={handleClose}
        message={msg}
        action={
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      <JobViewPopup selectedJob={selectedJob} />
    </>
  );
}
export default EmpBookmarksPage;
