import React, { useState, useEffect } from "react";
import { IconButton, Tooltip, Snackbar, Button } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import { locationOptions } from "../../../../common/static-data";
import { db } from "../../../../../config/fbConfig";

// Validation schema focused on Location, Specialization, and Designation
const validationSchema = Yup.object({
  designation: Yup.string().required("Designation is required"),
  specialization: Yup.string().required("Specialization is required"),
  location: Yup.string().required("Location is required"),
});

function SectionCandidateLookingInfo({ LookingFor, userUUID }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [designationOptions, setDesignationOptions] = useState([]);
  const [specializationOptions, setSpecializationOptions] = useState([]);

  const collectionName = "Public";
  const docName = "Designation";

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  const handleFormSubmit = async (values) => {
    const data = {
      LookingFor: {
        Designation: values.designation,
        Specialization: values.specialization,
        Location: values.location,
      },
    };

    try {
      await setDoc(doc(db, "Verify", userUUID), data, { merge: true });
      setMsg("Saved successfully");
      setOpen(true);
      setIsEditMode(false);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, collectionName, docName),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const docData = docSnapshot.data();
          const fetchedData = Object.entries(docData).map(([key, value]) => ({
            Id: key,
            label: value.Tag,
          }));
          setDesignationOptions([
            { Id: "alldesignation", label: "All Designation" },
            ...fetchedData,
          ]);
        }
      }
    );
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "Public", "Specialization"),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const docData = docSnapshot.data();
          const fetchedData = Object.entries(docData).map(([key, value]) => ({
            Id: key,
            label: value.Tag,
          }));
          setSpecializationOptions([
            { Id: "allspecialization", label: "All Specialization" },
            ...fetchedData,
          ]);
        }
      }
    );
    return () => unsubscribe();
  }, []);

  return (
    <Formik
      initialValues={{
        designation: LookingFor?.Designation || "",
        specialization: LookingFor?.Specialization || "",
        location: LookingFor?.Location || "",
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleFormSubmit}
    >
      <Form>
        <div className="panel panel-default">
          <div className="panel-heading wt-panel-heading p-a20">
            <h4 className="panel-title m-a0">
              Looking For &nbsp; ( &nbsp;
              <span
                style={{
                  color:
                    LookingFor?.Status === "Not Updated"
                      ? "red"
                      : LookingFor?.Status === "Not Verified"
                      ? "blue"
                      : "green",
                }}
              >
                {LookingFor?.Status}
              </span>
              &nbsp; )
            </h4>
          </div>
          <div className="edit-button-end">
            <Tooltip title={isEditMode ? "Close" : "Edit"}>
              <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                {isEditMode ? (
                  <CloseIcon fontSize="small" />
                ) : (
                  <EditIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <div className="panel-body wt-panel-body p-a20 m-b30">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Designation</label>
                  <Field
                    as="select"
                    name="designation"
                    className="form-control"
                    disabled={!isEditMode}
                    style={{
                      backgroundColor:
                        isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                    }}
                  >
                    <option value="">Select...</option>
                    {designationOptions?.slice(1)?.map((designation) =>
                      typeof designation === "object" ? (
                        <option
                          key={designation.value}
                          value={designation.value}
                        >
                          {designation.label}
                        </option>
                      ) : (
                        <option key={designation} value={designation}>
                          {designation}
                        </option>
                      )
                    )}
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="designation"
                    className="error-message"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Specialization</label>
                  <Field
                    as="select"
                    name="specialization"
                    className="form-control"
                    disabled={!isEditMode}
                    style={{
                      backgroundColor:
                        isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                    }}
                  >
                    <option value="">Select...</option>
                    {specializationOptions?.slice(1)?.map((specialization) =>
                      typeof specialization === "object" ? (
                        <option
                          key={specialization.value}
                          value={specialization.value}
                        >
                          {specialization.label}
                        </option>
                      ) : (
                        <option key={specialization} value={specialization}>
                          {specialization}
                        </option>
                      )
                    )}
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="specialization"
                    className="error-message"
                    style={{ color: "red" }}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Location</label>
                  <Field
                    as="select"
                    name="location"
                    className="form-control"
                    disabled={!isEditMode}
                    style={{
                      backgroundColor:
                        isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                    }}
                  >
                    <option value="">Select...</option>
                    {locationOptions?.slice(1)?.map((location) =>
                      typeof location === "object" ? (
                        <option key={location.value} value={location.value}>
                          {location.label}
                        </option>
                      ) : (
                        <option key={location} value={location}>
                          {location}
                        </option>
                      )
                    )}
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="location"
                    className="error-message"
                    style={{ color: "red" }}
                  />
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="text-left">
                  <Button
                    disabled={!isEditMode}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor:
                        isEditMode === true ? "#14ca4f" : "#14ca4f2e",
                    }}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msg}
          action={
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Form>
    </Formik>
  );
}

export default SectionCandidateLookingInfo;
