import { createSlice } from "@reduxjs/toolkit";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

const initialState = {
  canAds: [],
  canDashboard: [],
  canProfile: [],
  canMessages: [],
  canNotifications: [],
  loading: true,
  error: null,
};

//Action of Slice
const CanDashboardSlice = createSlice({
  name: "CanDashboard",
  initialState,
  reducers: {
    setDashboardStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setDashboardSuccess: (state, action) => {
      state.loading = false;
      state.canDashboard = action.payload.Dashboard;
      state.canAds = action.payload.Ads;
      state.canProfile = action.payload.Profile;
      state.canMessages = action.payload.Messages;
      state.canNotifications = action.payload.Notifications;
    },
    setDashboardFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const { setDashboardStart, setDashboardSuccess, setDashboardFailure } =
  CanDashboardSlice.actions;
export default CanDashboardSlice.reducer;

export const fetchDashboard = (userUUID) => (dispatch) => {
  console.log(userUUID, "...................................userUUID...45");

  try {
    dispatch(setDashboardStart());

    const docRef = doc(firestore, "Users", userUUID);

    // Set up the onSnapshot listener
    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const dashboardData = docSnap.data();
          dispatch(setDashboardSuccess(dashboardData));
        } else {
          dispatch(setDashboardFailure("Document not found"));
        }
      },
      (error) => {
        dispatch(setDashboardFailure(error.message));
      }
    );

    // Optionally return the unsubscribe function if you need to stop listening later
    return unsubscribe;
  } catch (error) {
    dispatch(setDashboardFailure(error.message));
  }
};
