import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import SectionPricingMonthly from "../../public-user/sections/pricing/section-pricing-monthly";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";

function EmpPricingPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch]);

  return (
    <Box>
      <div className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Pricing</h2>
            <div className="breadcrumbs">
              <a>Home</a>
              <a>Dashboard</a>
              <span>Pricing Details</span>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            <NavLink to={empAds?.Dashboard_Header_Link} target="_blank">
              <img
                src={empAds?.Pricing_Header}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      </div>
      <Grid container spacing={3} alignItems="center"></Grid>

      <Box sx={{ marginTop: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <SectionPricingMonthly />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginTop: "20px", textAlign: "center" }}>
        <NavLink to={empAds?.Pricing_Footer_Link} target="_blank">
          <img
            src={empAds?.Pricing_Footer}
            alt="Banner Image"
            style={{
              width: "100%",
              objectFit: "cover",
            }}
          />
        </NavLink>
      </Box>
    </Box>
  );
}

export default EmpPricingPage;
