import React from "react";
import { db } from "../../config/fbConfig";
import {
  onSnapshot,
  doc,
  updateDoc,
  deleteField,
  serverTimestamp,
} from "firebase/firestore";

export const designationOptions = [
  { label: "All Designation", value: "all-designation" },
  { label: "Anaesthetist", value: "anaesthetist" },
  { label: "Cardiologist", value: "cardiologist" },
  { label: "Cardio thoracic Surgeon", value: "cardio-thoracic-surgeon" },
  { label: "ENT Surgeon", value: "ent-surgeon" },
  { label: "Gastro Enterologist", value: "gastro-enterologist" },
  { label: "General Surgeon", value: "general-surgeon" },
  { label: "Intensivist", value: "intensivist" },
  { label: "Neurologist", value: "neurologist" },
  { label: "Neuro Surgeon", value: "neuro-surgeon" },
  { label: "Nephrologist", value: "nephrologist" },
  {
    label: "Obstetrician & Gynaecologist",
    value: "obstetrician-and-gynaecologist",
  },
  { label: "Ophthalmologist", value: "ophthalmologist" },
  { label: "Orthopedecian", value: "orthopedecian" },
  { label: "Paediatrician", value: "paediatrician" },
  { label: "Physician", value: "physician" },
  { label: "Pulmonologist", value: "pulmonologist" },
  { label: "Rheumatologist", value: "rheumatologist" },
  { label: "Urologist", value: "urologist" },
  { label: "Others", value: "others" },
];

export const specializationOptions = [
  { label: "All Specialization", value: "all-specialization" },
  { label: "Anaesthesiology", value: "anaesthesiology" },
  { label: "Baratrics", value: "baratrics" },
  { label: "Cardiology", value: "cardiology" },
  { label: "Cardio thoracic Surgery", value: "cardio-thoracic-surgery" },
  { label: "ENT", value: "ent" },
  { label: "Gastroenterology", value: "gastroenterology" },
  { label: "General Surgery", value: "general-surgery" },
  { label: "Hematology", value: "hematology" },
  { label: "Neurology", value: "neurology" },
  { label: "Neurosurgeon", value: "neurosurgeon" },
  { label: "Nephrology", value: "nephrology" },
  { label: "OBG", value: "obg" },
  { label: "Ophthalmology", value: "ophthalmology" },
  { label: "Orthopedics", value: "orthopedics" },
  { label: "Pediatrics", value: "pediatrics" },
  { label: "Pulmonology", value: "pulmonology" },
  { label: "Radiology", value: "radiology" },
  { label: "Rheumatology", value: "rheumatology" },
  { label: "Urology", value: "urology" },
  { label: "Others", value: "others" },
];

export const ProfessionalSector = [
  { label: "PRO_1", value: "PRO_1" },
  { label: "PRO_2", value: "PRO_2" },
  { label: "PRO_3", value: "PRO_3" },
];

export const locationOptions = [
  { label: "All Locations", value: "All Locations" },
  { label: "Bagalkote", value: "bagalkote" },
  { label: "Ballari", value: "ballari" },
  { label: "Belagavi", value: "belagavi" },
  { label: "Bengaluru Rural", value: "bengaluru-rural" },
  { label: "Bengaluru Urban", value: "bengaluru-urban" },
  { label: "Bidar", value: "bidar" },
  { label: "Chamarajanagara", value: "chamarajanagara" },
  { label: "Chikkaballapura", value: "chikkaballapura" },
  { label: "Chikkamagaluru", value: "chikkamagaluru" },
  { label: "Chitradurga", value: "chitradurga" },
  { label: "Dakshina Kannada", value: "dakshina-kannada" },
  { label: "Davangere", value: "davangere" },
  { label: "Dharwad", value: "dharwad" },
  { label: "Gadag", value: "gadag" },
  { label: "Hassan", value: "hassan" },
  { label: "Haveri", value: "haveri" },
  { label: "Kalaburagi", value: "kalaburagi" },
  { label: "Kodagu", value: "kodagu" },
  { label: "Kolar", value: "kolar" },
  { label: "Koppal", value: "koppal" },
  { label: "Mandya", value: "mandya" },
  { label: "Mysuru", value: "mysuru" },
  { label: "Raichur", value: "raichur" },
  { label: "Ramanagara", value: "ramanagara" },
  { label: "Shivamogga", value: "shivamogga" },
  { label: "Tumakuru", value: "tumakuru" },
  { label: "Udupi", value: "udupi" },
  { label: "Uttara Kannada", value: "uttara-kannada" },
  { label: "Vijayanagar", value: "vijayanagar" },
  { label: "Vijayapura", value: "vijayapura" },
  { label: "Yadgir", value: "yadgir" },
];

function StaticData() {
  const collectionName = "Public";
  const docName = "Designation";

  const [data, setData] = useState([]);
  console.log(data);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, collectionName, docName),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const docData = docSnapshot.data();
          if (docData) {
            const fetchedData = Object.entries(docData).map(([key, value]) => ({
              Id: key,
              ...value,
            }));
            setData(fetchedData);
          }
        }
      }
    );
    return () => unsubscribe();
  }, [collectionName, docName]);

  return <div></div>;
}

export default StaticData;
