import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Grid, IconButton, Typography, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "../../../common/loading/Index";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpPaymentHistory } from "../../../../reducers/employer/EmpPaymentHistorySlice";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Index from "../../candidate/common/no-data/Index";
import Logo from "../../../../images/logo-dark.png";

function EmpPaymentHistoryPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const paymentHistory = useSelector(
    (state) => state.EmpPaymentHistory.EmpPaymentHistory
  );

  useEffect(() => {
    loadScript("js/custom.js");
    dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpPaymentHistory(userUUID));
    dispatch(fetchGeneralempAds());
  }, [dispatch, userUUID]);

  const updatedData = paymentHistory?.map((item) => {
    const milliseconds = item.CreatedDate.seconds * 1000;
    const totalMilliseconds =
      milliseconds + item.CreatedDate.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    return {
      ...item,
      date: date,
      formattedDate: moment(date).format("MMM Do YYYY hh:mm A"),
    };
  });

  const generatePDF = (data) => {
    setInvoiceDetails(data);

    setTimeout(() => {
      // Scroll to the top to ensure full content is visible
      window.scrollTo(0, 0);

      const input = document.getElementById("invoice");

      html2canvas(input, { scale: 2, scrollY: 0 }) // Set scrollY to 0 for mobile view
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");

          const pdf = new jsPDF("p", "mm", "a4");
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          // Split canvas into pages if content is too long
          if (pdfHeight > pdf.internal.pageSize.getHeight()) {
            let yPos = 0;
            while (yPos < canvas.height) {
              const pageCanvas = document.createElement("canvas");
              pageCanvas.width = canvas.width;
              pageCanvas.height =
                (pdf.internal.pageSize.getHeight() * canvas.width) / pdfWidth;

              const ctx = pageCanvas.getContext("2d");
              ctx.drawImage(
                canvas,
                0,
                yPos,
                canvas.width,
                pageCanvas.height,
                0,
                0,
                pageCanvas.width,
                pageCanvas.height
              );

              const imgData = pageCanvas.toDataURL("image/png");
              pdf.addImage(
                imgData,
                "PNG",
                0,
                0,
                pdfWidth,
                pdf.internal.pageSize.getHeight()
              );
              yPos += pageCanvas.height;

              if (yPos < canvas.height) pdf.addPage();
            }
          } else {
            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          }

          pdf.save("invoice.pdf");
        })
        .catch((error) => console.error(error));
    }, 0);
  };

  const hiddenStyle = {
    position: "absolute",
    left: "-9999px",
    top: "-9999px",
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Payment History</h2>
                <div className="breadcrumbs">
                  <NavLink to="/">Home</NavLink>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                  <span>Payment History</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                {empAds?.Payment_Header && (
                  <NavLink to={empAds?.Payment_Header_Link} target="_blank">
                    <img
                      src={empAds?.Payment_Header}
                      alt="Banner"
                      className="img-fluid"
                    />
                  </NavLink>
                )}
              </div>
            </div>
          </div>

          {invoiceDetails && (
            <div
              id="invoice"
              style={{
                backgroundColor: "#f8f9fa",
                padding: "2rem",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div style={{ fontFamily: "Arial, sans-serif", color: "#333" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <img src={Logo} alt="Logo" style={{ height: "50px" }} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    {" "}
                    <div style={{ textAlign: "right" }}>
                      <h2
                        style={{
                          margin: 0,
                          fontSize: "1.5rem",
                          color: "#007bff",
                        }}
                      >
                        LACNAR SERVICES LLP
                      </h2>
                      <p style={{ margin: "0.5rem 0", fontSize: "0.9rem" }}>
                        GST: 29AALFL3473F1Z1
                      </p>
                      <p style={{ margin: "0.5rem 0", fontSize: "0.9rem" }}>
                        PAN: AALFL3473F
                      </p>
                      <p style={{ margin: "0.5rem 0", fontSize: "0.9rem" }}>
                        Email: info@lacnar.com
                      </p>
                      <p style={{ margin: "0.5rem 0", fontSize: "0.9rem" }}>
                        Address: 1715, E AND F BLOCK, DATTAGALLI,
                        <br />
                        MYSORE KUVEMPUNAGAR,
                        <br />
                        MYSORE-570023 KARNATAKA
                      </p>
                    </div>
                  </Grid>
                </Grid>

                <Paper
                  sx={{
                    borderTop: "2px solid #007bff",
                    marginTop: "1rem",
                    paddingTop: "1rem",
                    padding: "1rem",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ marginBottom: "1rem", color: "#007bff" }}
                  >
                    Invoice
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Date:
                      </Typography>
                      <Typography variant="body2">
                        {moment(invoiceDetails.date).format(
                          "MMM Do YYYY hh:mm A"
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Invoice No:
                      </Typography>
                      <Typography variant="body2">INV-001</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Client Name:
                      </Typography>
                      <Typography variant="body2">
                        {invoiceDetails.ClientName || "John Doe"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Client Address:
                      </Typography>
                      <Typography variant="body2">
                        123 Main Street, City, State, Zip
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Product Price:
                      </Typography>
                      <Typography variant="body2">
                        ₹ {invoiceDetails.ProductPrice || "1000"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Service Duration:
                      </Typography>
                      <Typography variant="body2">6 months</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        GST:
                      </Typography>
                      <Typography variant="body2">₹ 180</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Total Amount:
                      </Typography>
                      <Typography variant="body2">
                        ₹ {invoiceDetails.Amount}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Service From:
                      </Typography>
                      <Typography variant="body2">
                        {moment(invoiceDetails.serviceFrom).format(
                          "MMM Do YYYY"
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        To Date:
                      </Typography>
                      <Typography variant="body2">
                        {moment(invoiceDetails.serviceTo).format("MMM Do YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>

                {/* RECEIPT SECTION */}
                <Paper
                  sx={{
                    borderTop: "2px solid #28a745",
                    marginTop: "2rem",
                    paddingTop: "1rem",
                    padding: "1rem",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ marginBottom: "1rem", color: "#28a745" }}
                  >
                    Receipt
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Receipt No:
                      </Typography>
                      <Typography variant="body2">REC-001</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Date of Payment:
                      </Typography>
                      <Typography variant="body2">
                        {moment(invoiceDetails.paymentDate).format(
                          "MMM Do YYYY"
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Amount Paid:
                      </Typography>
                      <Typography variant="body2">
                        ₹ {invoiceDetails.AmountPaid}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Payment Mode:
                      </Typography>
                      <Typography variant="body2">
                        {invoiceDetails.paymentMode || "Cash"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Paid By:
                      </Typography>
                      <Typography variant="body2">
                        {invoiceDetails.paidBy || "John Doe"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <div>
                      <Typography variant="h6">Thank You!</Typography>
                      <Typography variant="body2">
                        For any queries, feel free to reach out.
                      </Typography>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
                        Amount in words:
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", fontSize: "1rem" }}
                      >
                        Rupees {invoiceDetails.AmountInWords}
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          )}

          {paymentHistory?.length > 0 ? (
            <div className="twm-pro-view-chart-wrap">
              <div className="col-lg-12 col-md-12 mb-4">
                <div className="panel panel-default site-bg-white m-t30">
                  <div className="panel-heading wt-panel-heading p-a20">
                    <h4 className="panel-tittle m-a0">
                      <i className="fas fa-money-bill" /> Payment History
                    </h4>
                  </div>
                  <div className="panel-body wt-panel-body">
                    <div className="twm-D_table p-a20 table-responsive">
                      <table
                        id="payment_history_table"
                        className="table table-bordered twm-payment-history-list-wrap"
                      >
                        <thead>
                          <tr>
                            <th>Sl No</th>
                            <th>Transaction ID</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {updatedData?.map((item, index) => (
                            <tr key={item.TransactionID}>
                              <td>{index + 1}</td>
                              <td>{item.TransactionID}</td>
                              <td>{item.BillName}</td>
                              <td>₹ {item.Amount}</td>
                              <td>{item.formattedDate}</td>
                              <td>
                                <span
                                  className={
                                    item.Status === "Success"
                                      ? "text-clr-green2"
                                      : "text-clr-red"
                                  }
                                >
                                  {item.Status}
                                </span>
                              </td>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton onClick={() => generatePDF(item)}>
                                  <DownloadIcon fontSize="small" />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Sl No</th>
                            <th>Transaction ID</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            {empAds?.Payment_Footer && (
              <NavLink to={empAds?.Payment_Footer_Link} target="_blank">
                <img
                  src={empAds?.Payment_Footer}
                  alt="Banner"
                  className="img-fluid"
                />
              </NavLink>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default EmpPaymentHistoryPage;
