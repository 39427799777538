import { useEffect, useState, useMemo } from "react";
import moment from "moment";
import {
  ThemeProvider,
  createTheme,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogs } from "../../../../../reducers/public-user/blogSlice";
import { publicUser } from "../../../../../globals/route-names";

function BlogListPage() {
  const dispatch = useDispatch();
  const { blogs, loading } = useSelector((state) => state.blogList);

  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  const transformedBlogs = useMemo(() => {
    return blogs
      ?.map((item) => {
        if (item.Date && item.Date.seconds !== undefined) {
          const milliseconds = item.Date.seconds * 1000;
          const totalMilliseconds =
            milliseconds + item.Date.nanoseconds / 1000000;
          const date = new Date(totalMilliseconds);
          return {
            ...item,
            date: date,
            formattedDate: moment(date).format("MMM Do YYYY"),
          };
        }
        return item;
      })
      .sort((a, b) => b.date - a.date);
  }, [blogs]);

  const totalPages = Math.ceil(transformedBlogs?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = transformedBlogs.slice(startIndex, endIndex);

  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  };

  const itemStyle = {
    flex: "1 1 calc(50% - 20px)",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  };

  const themes = createTheme({
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            padding: "22px",
            fontWeight: "400",
            fontFamily: "Rubik",
            fontSize: "16px",
            lineHeight: "50px",
            height: "50px",
            borderRadius: "12px",
            "&.Mui-selected": {
              backgroundColor: "#046434",
              color: "white",
              "&:hover": {
                backgroundColor: "#046434",
                color: "white",
              },
            },
          },
        },
      },
    },
  });

  return (
    <div className="section-full p-t120 p-b90 site-bg-white">
      <div className="container">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div style={containerStyle}>
              {currentItems.map((item) => (
                <div style={itemStyle} key={item.Id}>
                  <div className="blog-post twm-blog-post-1-outer twm-blog-list-style">
                    <div className="wt-post-media">
                      <img
                        src={item.ImgUrl}
                        alt={item.Title}
                        style={{
                          height: "13.4rem",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="wt-post-info">
                      <div className="wt-post-meta">
                        <ul>
                          <li className="post-date">{item.formattedDate}</li>
                          <li className="post-author">
                            By{" "}
                            <span style={{ color: "#046434" }}>
                              {item.Author}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="wt-post-title">
                        <h4
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            lineHeight: "1.5",
                          }}
                          className="post-title"
                        >
                          <NavLink to={`${publicUser.blog.DETAIL}/${item.Id}`}>
                            {item.Title}
                          </NavLink>
                        </h4>
                      </div>
                      <div style={{ width: "300px", overflow: "hidden" }}>
                        <p
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            lineHeight: "1.5",
                          }}
                        >
                          {item.Description}
                        </p>
                      </div>
                      <div className="wt-post-readmore">
                        <NavLink
                          to={`${publicUser.blog.DETAIL}/${item.Id}`}
                          className="site-button-link site-text-primary"
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <ThemeProvider theme={themes}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                shape="rounded"
              />
            </ThemeProvider>
          </>
        )}
      </div>
    </div>
  );
}

export default BlogListPage;
