import { useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadScript } from "../../../../../globals/constants";
import SectionBlogsSidebar from "../../sections/blogs/sidebar/section-blogs-sidebar";
import { fetchBlogs } from "../../../../../reducers/public-user/blogSlice";

function BlogDetailPage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const blogs = useSelector((state) => state.blogList.blogs);
  const data = blogs?.find((blog) => blog.Id === id);

  const embedUrl = data?.Video
    ? `https://www.youtube.com/embed/${data.Video.split("v=")[1].split("&")[0]}`
    : "";

  const formattedDate = data
    ? moment(
        data?.Date?.seconds * 1000 + data?.Date?.nanoseconds / 1000000
      ).format("MMMM Do YYYY")
    : "";

  useEffect(() => {
    loadScript("js/custom.js");
  }, []);

  useEffect(() => {
    if (!blogs) {
      dispatch(fetchBlogs());
    }
  }, [dispatch, blogs]);

  return (
    <div className="section-full p-t120 p-b90 bg-white">
      <div className="container">
        <div className="section-content">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8 col-md-12">
              <div className="blog-post-single-outer">
                <div className="blog-post-single bg-white">
                  <div className="wt-post-info">
                    <div
                      className="wt-post-media m-b30"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={data?.ImgUrl}
                        alt="Blog"
                        style={{ height: "400px", width: "400px" }}
                      />
                    </div>
                    <div className="wt-post-title">
                      <div
                        className="wt-post-meta-list"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "1rem 0",
                        }}
                      >
                        <div className="wt-list-content post-date">
                          Posted Date : {formattedDate}
                        </div>
                        <div className="wt-list-content post-author">
                          Author : {data?.Author}
                        </div>
                      </div>
                      <h3 className="post-title">{data?.Title}</h3>
                    </div>
                    <div className="wt-post-description">
                      <p>{data?.Description}</p>
                    </div>
                    {embedUrl && (
                      <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                        <iframe
                          style={{ height: "400px", width: "100%" }}
                          className="embedded-video"
                          src={embedUrl}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    )}
                    <div className="wt-post-description mt-3">
                      <p>{data?.Description1}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 rightSidebar">
              <SectionBlogsSidebar id={id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetailPage;
