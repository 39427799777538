function SectionLocation() {
  return (
    <>
      <h4 className="section-head-small mb-4">Location</h4>
      <div className="twm-s-map-iframe">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5370.536653444657!2d76.62121359999999!3d12.2846846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf654bd288d617%3A0x2eafa13f04f1289b!2sLacnar!5e1!3m2!1sen!2sin!4v1728042087725!5m2!1sen!2sin"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          title="Location Map"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
        ></iframe>
      </div>
    </>
  );
}

export default SectionLocation;
