function SectionFaqJobs() {
  return (
    <>
      <div className="tw-faq-section">
        <div className="accordion tw-faq" id="sf-faq-accordion-2">
          {/*one*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ1-2"
              aria-expanded="false"
            >
              How does the Lacnar platform works ?
            </button>
            <div
              id="FAQ1-2"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-2"
            >
              <div className="accordion-body">
                Lacnar acts as a bridge between healthcare professionals and
                employers. Job seekers create profiles on the platform, where
                they can upload their resumes, list their qualifications, and
                specify their job preferences. Employers post job openings on
                Lacnar, and qualified candidates can apply directly through the
                platform. Lacnar also offers features like job alerts, saved
                searches, and employer profiles to help users find the best
                opportunities.
              </div>
            </div>
          </div>
          {/*two*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ2-2"
              aria-expanded="false"
            >
              What types of jobs can I find on Lacnar ?
            </button>
            <div
              id="FAQ2-2"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-2"
            >
              <div className="accordion-body">
                Lacnar offers a wide range of job opportunities for healthcare
                professionals in various academic institutions related to
                healthcare, Hospitals, Diagnostic centres, Laboratories,
                clinics, research institutions, home healthcare, Pharmaceutical
                Companies, including full-time and part-time positions.
              </div>
            </div>
          </div>
          {/*three*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ3-2"
              aria-expanded="false"
            >
              How do I search for jobs on Lacnar ?
            </button>
            <div
              id="FAQ3-2"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-2"
            >
              <div className="accordion-body">
                You can search for jobs by using the search bar on the homepage
                and filtering by keywords, location, job type (full-time or
                part-time), and specialty. You can also browse through the
                featured jobs or explore job postings by category.
              </div>
            </div>
          </div>
          {/*four*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ4-2"
              aria-expanded="false"
            >
              What is the application process like on Lacnar ?
            </button>
            <div
              id="FAQ4-2"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-2"
            >
              <div className="accordion-body">
                The application process is simple. Once you find a job you're
                interested in, you can apply directly through the platform by
                submitting your resume and cover letter. Some job postings may
                have additional requirements, such as completing an online
                assessment or providing references.
              </div>
            </div>
          </div>
          {/*five*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ5-2"
              aria-expanded="false"
            >
              How can I find full-time job opportunities ?
            </button>
            <div
              id="FAQ5-2"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-2"
            >
              <div className="accordion-body">
                To find full-time jobs, filter your search by "Full-time" under
                the "Job Type" category. You can also explore job postings in
                hospitals, clinics, and other healthcare facilities that
                typically offer full-time positions.
              </div>
            </div>
          </div>
          {/*six*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ6-2"
              aria-expanded="false"
            >
              Are there part-time job opportunities available ?
            </button>
            <div
              id="FAQ6-2"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-2"
            >
              <div className="accordion-body">
                Yes, Lacnar offers a variety of part-time job opportunities for
                healthcare professionals. You can filter your search by
                "Part-time" under the "Job Type" category or explore job
                postings in clinics, home healthcare agencies, and other
                settings that often have part-time positions.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ7-2"
              aria-expanded="false"
            >
              How can I increase my chances of getting hired ?
            </button>
            <div
              id="FAQ7-2"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-2"
            >
              <div className="accordion-body">
                To increase your chances of getting hired, make sure your resume
                and cover letter are tailored to each job application. Highlight
                your relevant skills, experience, and qualifications. Network
                with other healthcare professionals and build relationships in
                the field. Consider attending industry events or conferences to
                learn about new opportunities and connect with potential
                employers
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionFaqJobs;
