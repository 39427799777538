import React, { useState, useMemo } from "react";
import {
  ThemeProvider,
  createTheme,
  Pagination,
  Stack,
  Tooltip,
  IconButton,
  Snackbar,
  CircularProgress,
  Box,
} from "@mui/material";
import {
  RemoveRedEye as RemoveRedEyeIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { doc, setDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import JobViewPopup from "../../../../common/popups/popup-job-view";
import { db } from "../../../../../config/fbConfig";

function SectionJobsList({ data, loading }) {
  console.log(data);
  const navigate = useNavigate();
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const userRole = useSelector((state) => state.AuthStatus.userRole);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedJob, setSelectedJob] = useState(null);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const itemsPerPage = 10;
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;

  const currentItems = useMemo(
    () => data?.slice(startIndex, startIndex + itemsPerPage),
    [data, startIndex]
  );

  const handleViewClick = (job) => setSelectedJob(job);

  const handleClose = (_, reason) => reason !== "clickaway" && setOpen(false);

  const handleJobApply = async () => {
    if (!userRole) {
      navigate("/login");
      return;
    }
    if (!selectedJob) return setMsg("Selected job is undefined");
    const jobData = {
      CandidateId: userUUID,
      Company: selectedJob.Company || "",
      Designation: selectedJob.Designation || "",
      District: selectedJob.District || "",
      Experience: selectedJob.Experience || "",
      JobDescription: selectedJob.JobDescription || "",
      JobTitle: selectedJob.JobTitle || "",
      JobType: selectedJob.JobType || "",
      Logo: selectedJob.Logo || "",
      Posts: selectedJob.Posts || "",
      Specialization: selectedJob.Specialization || "",
      State: selectedJob.State || "",
      Status: selectedJob.Status || "",
      JobId: selectedJob.id || "",
    };
    try {
      await setDoc(doc(db, "JobApplied", uuidv4()), jobData);
      setOpen(true);
      setMsg("Job Applied Successfully");
    } catch (error) {
      console.error("Error writing document: ", error);
      setMsg(`Error: ${error.message}`);
    }
  };

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiPaginationItem: {
            styleOverrides: {
              root: {
                padding: "22px",
                fontWeight: "400",
                fontFamily: "Rubik",
                fontSize: "16px",
                lineHeight: "50px",
                height: "50px",
                borderRadius: "12px",
                "&.Mui-selected": {
                  backgroundColor: "#046434",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#046434",
                    color: "white",
                  },
                },
              },
            },
          },
        },
      }),
    []
  );

  return (
    <div>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <div className="twm-jobs-list-wrap">
          {currentItems?.length > 0 ? (
            <ul>
              {currentItems.map((item, index) => (
                <li key={index}>
                  <div className="twm-jobs-list-style1 mb-5">
                    <div className="twm-media">
                      <img src={item.Logo} alt="#" />
                    </div>
                    <div className="twm-mid-content">
                      <h4>{item.JobTitle}</h4>

                      <p className="twm-job-address">
                        {item.District}, {item.State}
                      </p>
                      <div>{item.Designation}</div>
                    </div>
                    <div className="twm-right-content">
                      <div className="twm-jobs-category green">
                        <span
                          className="twm-bg-green"
                          style={{
                            backgroundColor:
                              item.JobType === 0 ? "#14ca4f" : "#046434",
                          }}
                        >
                          {item.JobType === 0 ? "Full-Time" : "Part-Time"}
                        </span>
                      </div>
                      <div style={{ marginTop: "1rem" }}>
                        <Tooltip title="View Job Details">
                          <a
                            data-bs-toggle="modal"
                            href="#saved-jobs-view"
                            role="button"
                            class="custom-toltip"
                            className="aplybtn"
                            onClick={() => handleViewClick(item)}
                          >
                            <IconButton onClick={() => handleViewClick(item)}>
                              <RemoveRedEyeIcon style={{ fontSize: "15px" }} />
                            </IconButton>
                          </a>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h4>No Data Found</h4>
            </div>
          )}
        </div>
      )}

      <ThemeProvider theme={theme}>
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            shape="rounded"
          />
        </Stack>
      </ThemeProvider>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msg}
        action={
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      <JobViewPopup
        selectedJob={selectedJob}
        show={false}
        onApply={handleJobApply}
        userRole={userRole}
      />
    </div>
  );
}
export default SectionJobsList;
