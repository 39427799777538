import { useEffect, useState, useMemo } from "react";
import {
  ThemeProvider,
  createTheme,
  Pagination,
  IconButton,
  Stack,
  Tooltip,
  Snackbar,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import Index from "../common/no-data/Index";
import { firestore, db } from "../../../../config/fbConfig";

import { loadScript } from "../../../../globals/constants";
import CircularProgress from "../../../common/loading/Index";
import JobViewPopup from "../../../common/popups/popup-job-view";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import { fetchJobAlerts } from "../../../../reducers/candidate/CanJobAlertSlice";
import { fetchGeneralAds } from "../../../../reducers/public-user/adsSlice";

function CanJobAlertPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.CanDashboard.loading);
  const CanJobAlertList = useSelector((state) => state.CanJobAlert);
  const ads = useSelector((state) => state.ads.ads);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const lookingFor = useSelector(
    (state) => state.CanDashboard.canProfile.LookingFor
  );
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [updatedItems, setUpdatedItems] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const CanDashboard = useSelector((state) => state.CanDashboard.canDashboard);

  const formattedItems = CanJobAlertList?.CanJobAlert?.map((item) => {
    const milliseconds = item.Date?.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date?.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const newDate = moment(date).format("MMM Do YYYY");
    return {
      ...item,
      formattedDate: newDate,
    };
  });

  const itemsPerPage = 10;
  const totalPages = Math.ceil(formattedItems?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, formattedItems?.length);
  const currentItems = formattedItems.slice(startIndex, endIndex);

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) {
      dispatch(fetchDashboard(userUUID));
    }
  }, [dispatch, userUUID]);

  useEffect(() => {
    if (lookingFor) {
      dispatch(fetchJobAlerts(lookingFor));
    }
  }, [dispatch, lookingFor]);

  useEffect(() => {
    if (CanJobAlertList?.CanJobAlert?.length > 0) {
      setUpdatedItems(CanJobAlertList.CanJobAlert); // Update state with actual data
    }
  }, [CanJobAlertList]); // Update state when CanJobAlertList changes

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralAds());
  }, [dispatch]);

  const handleViewClick = (job) => {
    setSelectedJob(job); // Set the selected job data
  };

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  const handleJobApply = async () => {
    if (!selectedJob) {
      setMsg("Selected job is undefined");
      return;
    }
    try {
      const jobData = {
        CandidateId: userUUID,
        Company: selectedJob?.Company || "",
        CreatedAt: serverTimestamp(),
        Designation: selectedJob?.Designation || "",
        District: selectedJob?.District || "",
        Experience: selectedJob?.Experience || "",
        JobDescription: selectedJob?.JobDescription || "",
        JobTitle: selectedJob?.JobTitle || "",
        JobType: selectedJob?.JobType || "",
        Logo: selectedJob?.Logo || "",
        Posts: selectedJob?.Posts || "",
        Specialization: selectedJob?.Specialization || "",
        State: selectedJob?.State || "",
        Status: selectedJob?.Status || "",
        JobId: selectedJob?.id || "",
      };

      const docRef = doc(db, "JobApplied", uuidv4());
      await setDoc(docRef, jobData);
      setOpen(true);
      setMsg("Job Applied Successfully");
    } catch (error) {
      console.error("Error writing document: ", error);
      setMsg(`Error: ${error.message}`);
    }
  };

  return (
    <>
      {CanJobAlertList.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Job Alerts</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Job Alerts</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={ads?.JobAlerts_Header_Link} target="_blank">
                  <img
                    src={ads?.JobAlerts_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {updatedItems?.length > 0 ? (
            <>
              <div className="twm-right-section-panel candidate-save-job site-bg-gray">
                <div className="product-filter-wrap d-flex justify-content-between align-items-center">
                  <span className="woocommerce-result-count-left">
                    Job Alerts
                  </span>
                </div>
                <div className="table-responsive">
                  <table className="table twm-table table-striped table-borderless">
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>Title</th>
                        <th>Jobs Description</th>
                        <th style={{ minWidth: "140px" }}>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.JobTitle}</td>
                            <td>{item.JobDescription}</td>
                            <td>{item.formattedDate}</td>
                            <td>
                              <div
                                className="twm-table-controls"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "start",
                                }}
                              >
                                <ul className="twm-DT-controls-icon list-unstyled d-flex justify-content-end">
                                  <li>
                                    {CanDashboard?.TopBar_1 !==
                                    "Profile : Verified" ? (
                                      <>
                                        <Tooltip title={CanDashboard?.TopBar_1}>
                                          <span>
                                            <IconButton
                                              disabled
                                              style={{
                                                color: "grey",
                                              }}
                                            >
                                              <RemoveRedEyeIcon
                                                style={{
                                                  fontSize: "15px",
                                                }}
                                              />
                                            </IconButton>
                                          </span>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <a
                                        data-bs-toggle="modal"
                                        href="#saved-jobs-view"
                                        role="button"
                                        className="custom-toltip"
                                      >
                                        <Tooltip title={"View Job"}>
                                          <IconButton
                                            onClick={() =>
                                              handleViewClick(item)
                                            }
                                          >
                                            <RemoveRedEyeIcon
                                              style={{
                                                fontSize: "15px",
                                                marginTop: "5px",
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </a>
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <JobViewPopup
                selectedJob={selectedJob}
                show={false}
                onApply={handleJobApply}
              />

              <Snackbar
                open={open}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                autoHideDuration={2000}
                onClose={handleClose}
                message={msg}
                action={
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              />

              <div style={{ marginTop: "1rem" }}>
                <ThemeProvider theme={themes}>
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, value) => setCurrentPage(value)}
                      shape="rounded"
                    />
                  </Stack>
                </ThemeProvider>
              </div>
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Index />
              </div>
            </>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-3 mt-5">
            <NavLink to={ads?.JobAlerts_Footer_Link} target="_blank">
              <img
                src={ads?.JobAlerts_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}

export default CanJobAlertPage;

const themes = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          padding: "22px",
          fontWeight: "400",
          fontFamily: "Rubik",
          fontSize: "16px",
          lineHeight: "50px",
          height: "50px",
          borderRadius: "12px",
          "&.Mui-selected": {
            backgroundColor: "#046434",
            color: "white",
            "&:hover": {
              backgroundColor: "#046434",
              color: "white",
            },
          },
        },
      },
    },
  },
});
