import React, { useState } from "react";
import { useSelector } from "react-redux";
import { loadScript } from "../../../../globals/constants";

function EmpPasswordPage() {
  useState(() => {
    loadScript("js/custom.js");
  });
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  function handleSubmit(event) {
    event.preventDefault();
  }

  const EmpPasswordList = useSelector((state) => state.EmpPassword.List);

  return (
    <div className="twm-right-section-panel site-bg-gray">
      <form onSubmit={handleSubmit}>
        {/*Basic Information*/}
        <div className="panel panel-default">
          <div className="panel-heading wt-panel-heading p-a20">
            <h4 className="panel-tittle m-a0">Change Password</h4>
          </div>
          <div className="panel-body wt-panel-body p-a20 ">
            <div className="row">
              {EmpPasswordList.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>{item.OldPassword}</label>
                      <div className="ls-inputicon-box">
                        <input
                          className="form-control wt-form-control"
                          name="old_password"
                          id="oldpassword"
                          type="password"
                          placeholder
                          onChange={handleInputChange}
                          value={formData.old_password}
                        />
                        <i className="fs-input-icon fa fa-asterisk " />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>{item.NewPassword}</label>
                      <div className="ls-inputicon-box">
                        <input
                          className="form-control wt-form-control"
                          name="new_password"
                          type="newpassword"
                          placeholder
                          onChange={handleInputChange}
                          value={formData.new_password}
                        />
                        <i className="fs-input-icon fa fa-asterisk" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>{item.ConfirmPassword}</label>
                      <div className="ls-inputicon-box">
                        <input
                          className="form-control wt-form-control"
                          name="confirm_password"
                          type="confirmpassword"
                          placeholder
                          onChange={handleInputChange}
                          value={formData.confirm_password}
                        />
                        <i className="fs-input-icon fa fa-asterisk" />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="text-left">
                  <button type="submit" className="site-button">
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EmpPasswordPage;
