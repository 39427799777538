function SectionFaqPayments() {
  return (
    <>
      <div className="tw-faq-section">
        <div className="accordion tw-faq" id="sf-faq-accordion-3">
          {/*one*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ1-3"
              aria-expanded="false"
            >
              How can I post a requirement on Lacnar ?
            </button>
            <div
              id="FAQ1-3"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-3"
            >
              <div className="accordion-body">
                To post a requirement on Lacnar, you'll need to create an
                employer account. Once your account is set up, you can follow
                the platform's guidelines to create and publish your job listing
                and other requirements.
              </div>
            </div>
          </div>
          {/*two*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ2-3"
              aria-expanded="false"
            >
              What information should I include in my job posting ?
            </button>
            <div
              id="FAQ2-3"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-3"
            >
              <div className="accordion-body">
                When creating your job posting, be sure to include clear and
                concise details about the position, such as the job title,
                designation, qualifications, experience requirements,
                responsibilities, salary range, and location.
              </div>
            </div>
          </div>
          {/*three*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ3-3"
              aria-expanded="false"
            >
              How do I manage applicants on Lacnar ?
            </button>
            <div
              id="FAQ3-3"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-3"
            >
              <div className="accordion-body">
                However we assess the authenticity of candidates profile and
                then we connect the suitable and verified candidate to you.at
                the same time, Lacnar also provide tools to help you manage
                applicants. You can review resumes, cover letters, and other
                relevant documents. You can also communicate with candidates
                directly through the platform's messaging system.
              </div>
            </div>
          </div>
          {/*four*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ4-3"
              aria-expanded="false"
            >
              How can I attract top talent to my healthcare institution ?
            </button>
            <div
              id="FAQ4-3"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-3"
            >
              <div className="accordion-body">
                To attract top talent, create compelling job postings that
                highlight the benefits and uniqueness of the position working at
                your institution. Consider offering competitive salaries,
                benefit packages, and opportunities for professional
                development.
              </div>
            </div>
          </div>
          {/*five*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ5-3"
              aria-expanded="false"
            >
              How can I ensure a smooth hiring process on Lacnar ?
            </button>
            <div
              id="FAQ5-3"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-3"
            >
              <div className="accordion-body">
                To streamline the hiring process, use Lacnar's features to
                efficiently review applications and communicate with candidates.
                Set clear expectations and timelines throughout the process to
                avoid delays. Using premium package feature of Lacnar, the
                effective and smooth hiring process is possible
              </div>
            </div>
          </div>
          {/*six*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ6-3"
              aria-expanded="false"
            >
              What are the costs associated with using Lacnar ?
            </button>
            <div
              id="FAQ6-3"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-3"
            >
              <div className="accordion-body">
                Lacnar's pricing may vary depending on your specific needs and
                the number of job postings you plan to create. You can contact
                Lacnar's customer support for more information about their
                pricing plans or visit the lacnar pricing page. (note to
                developer : add pricing page link here)
              </div>
            </div>
          </div>
          {/*seven*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ7-3"
              aria-expanded="false"
            >
              How can Lacnar ensure the security and privacy of applicant data
              on Lacnar ?
            </button>
            <div
              id="FAQ7-3"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-3"
            >
              <div className="accordion-body">
                Lacnar is committed to protecting the privacy and security of
                applicant data. Lacnar have implemented measures to safeguard
                information and comply with relevant data protection
                regulations.
              </div>
            </div>
          </div>
          {/*eight*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ8-3"
              aria-expanded="false"
            >
              How can I verify the qualifications and credentials of healthcare
              professionals on Lacnar ?
            </button>
            <div
              id="FAQ8-3"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-3"
            >
              <div className="accordion-body">
                Lacnar takes care to authenticate the quality of healthcare
                professionals listed on its platform. While they may not have a
                rigorous verification process for every candidate, they often
                require applicants to provide relevant documentation, such as
                licenses, certifications, and references.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ9-3"
              aria-expanded="false"
            >
              Additional Tips
            </button>
            <div
              id="FAQ9-3"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion-3"
            >
              <div className="accordion-body">
                <b>Utilize Lacnar's search and filtering features</b> to find
                candidates that match your specific requirements.
              </div>
              <div className="accordion-body">
                <b>Respond to applicants promptly </b> to show your interest and
                maintain a positive candidate experience.
              </div>

              <div className="accordion-body">
                <b>Consider using Lacnar's premium features</b> for additional
                benefits, such as extended job postings or enhanced applicant
                tracking.
              </div>
              <div className="accordion-body">
                <b>Provide feedback to candidates</b> even if they are not
                selected for the position. This can help improve their job
                search and maintain a positive relationship with the
                institution.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionFaqPayments;
