import { createSlice } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

const initialState = {
  empAds: {},
  loading: true,
  error: null,
};

//Action of Slice
const empAdsSlice = createSlice({
  name: "empAds",
  initialState,
  reducers: {
    setempAdsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setempAdsSuccess: (state, action) => {
      state.loading = false;
      state.empAds = action.payload;
    },
    setempAdsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const { setempAdsStart, setempAdsSuccess, setempAdsFailure } =
  empAdsSlice.actions;
export default empAdsSlice.reducer;

export const fetchGeneralempAds = () => async (dispatch) => {
  try {
    dispatch(setempAdsStart());
    const docRef = doc(firestore, "Public", "EmployerGeneral");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const empAdsData = docSnap.data();
      dispatch(setempAdsSuccess(empAdsData));
    } else {
      console.error("Document not found");
      dispatch(setempAdsFailure("Document not found"));
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    dispatch(setempAdsFailure(error.message));
  }
};
