import React, { useEffect } from "react";
import { Grid, Typography, Button, Alert, Stack } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "../../../common/loading/Index";
import Index from "../common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import { fetchCanNotification } from "../../../../reducers/candidate/CanNotificationSlice";
import { fetchGeneralAds } from "../../../../reducers/public-user/adsSlice";

function CanNotificationPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.CanDashboard.loading);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const ads = useSelector((state) => state.ads.ads);
  const CanNotificationList = useSelector(
    (state) => state.CanNotification.CanNotification
  );

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading) dispatch(fetchDashboard(userUUID));
    dispatch(fetchCanNotification(userUUID));
    dispatch(fetchGeneralAds());
  }, [dispatch, loading, userUUID]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="row">
            <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
              <div className="wt-admin-right-page-header clearfix">
                <h2>Notifications</h2>
                <div className="breadcrumbs">
                  <NavLink to="/">Home</NavLink>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                  <span>Notifications</span>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
              <NavLink to={ads?.Msg_Header_Link} target="_blank">
                <img
                  src={ads?.Msg_Header}
                  alt="Banner Image"
                  className="img-fluid"
                />
              </NavLink>
            </div>
          </div>

          {CanNotificationList?.length > 0 ? (
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              {CanNotificationList.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <NavLink to={item.Link} target="_blank">
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert
                        icon={<NotificationsIcon fontSize="inherit" />}
                        severity={item.Read ? "success" : "info"}
                        action={
                          <Button color="inherit" size="small">
                            <Typography
                              style={{
                                fontWeight: item.Read ? "normal" : "bold",
                                fontSize: "14px",
                                fontFamily: "Rubik",
                                textTransform: "capitalize",
                              }}
                            >
                              {item.Author}
                            </Typography>
                          </Button>
                        }
                        sx={{
                          color: item.Read ? "grey" : "black",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: item.Read ? "normal" : "bold",
                            fontSize: "15px",
                            fontFamily: "Rubik",
                          }}
                        >
                          {item.Title}
                        </Typography>
                        <Typography
                          style={{ fontSize: "14px", fontFamily: "Rubik" }}
                        >
                          {item.Description}
                        </Typography>
                      </Alert>
                    </Stack>
                  </NavLink>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            <NavLink to={ads?.Msg_Footer_Link} target="_blank">
              <img
                src={ads?.Msg_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}

export default CanNotificationPage;
