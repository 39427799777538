import { useEffect } from "react";
import CountUp from "react-countup";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";

function EmpDashboardPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const cards = useSelector((state) => state.EmpDashboard.empDashboard);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch]);

  return (
    <>
      <div className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Dashboard</h2>
            <div className="breadcrumbs">
              <a>Home</a>
              <a>Dashboard</a>
              <span>Deals</span>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            <NavLink to={empAds?.Dashboard_Header_Link} target="_blank">
              <img
                src={empAds?.Dashboard_Header}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      </div>
      <div className="twm-dash-b-blocks mb-5">
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body gradi-1 dashboard-card ">
                <div className="wt-card-wrap">
                  <div className="wt-card-icon">
                    <i className="far fa-address-book" />
                  </div>
                  <div className="wt-card-right wt-total-active-listing counter ">
                    <CountUp end={cards?.Card_1} duration={10} />
                  </div>
                  <div className="wt-card-bottom ">
                    <h4 className="m-b0"> Resumes</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body gradi-2 dashboard-card ">
                <div className="wt-card-wrap">
                  <div className="wt-card-icon">
                    <i className="far fa-file-alt" />
                  </div>
                  <div className="wt-card-right  wt-total-listing-view counter ">
                    <CountUp end={cards?.Card_2} duration={10} />
                  </div>
                  <div className="wt-card-bottom">
                    <h4 className="m-b0">Payments</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body gradi-3 dashboard-card ">
                <div className="wt-card-wrap">
                  <div className="wt-card-icon">
                    <i className="far fa-envelope" />
                  </div>
                  <div className="wt-card-right wt-total-listing-review counter ">
                    <CountUp end={cards?.Card_3} duration={10} />
                  </div>
                  <div className="wt-card-bottom">
                    <h4 className="m-b0">Tickets</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body gradi-4 dashboard-card ">
                <div className="wt-card-wrap">
                  <div className="wt-card-icon">
                    <i className="far fa-bell" />
                  </div>
                  <div className="wt-card-right wt-total-listing-bookmarked counter ">
                    <CountUp end={cards?.Card_4} duration={10} />
                  </div>
                  <div className="wt-card-bottom">
                    <h4 className="m-b0">Notifications</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
        <NavLink to={empAds?.Dashboard_Footer_Link} target="_blank">
          <img
            src={empAds?.Dashboard_Footer}
            alt="Banner Image"
            className="img-fluid"
          />
        </NavLink>
      </div>
    </>
  );
}

export default EmpDashboardPage;
