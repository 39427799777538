import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  candidate,
  canRoute,
  publicUser,
} from "../../../../globals/route-names";
import JobZImage from "../../../common/jobz-img";
import { loadScript, setMenuActive } from "../../../../globals/constants";

function CanSidebarSection(props) {
  const currentpath = useLocation().pathname;
  const [activeMenuItem, setActiveMenuItem] = useState(currentpath);

  useEffect(() => {
    loadScript("js/custom.js");
    loadScript("js/emp-sidebar.js");
  }, []);

  const handleMenuItemClick = (path) => {
    setActiveMenuItem(path);
    // Close sidebar on small screens
    if (window.innerWidth < 700) {
      props.onMenuItemClick();
    }
  };

  return (
    <>
      <nav
        id="sidebar-admin-wraper"
        className={props.sidebarActive ? "" : "active"}
      >
        <div className="page-logo">
          <NavLink to={publicUser.HOME2}>
            <JobZImage id="skin_page_logo" src="images/logo-dark.png" alt="" />
          </NavLink>
        </div>
        <div className="admin-nav scrollbar-macosx">
          <ul>
            <li
              className={setMenuActive(
                activeMenuItem,
                canRoute(candidate.DASHBOARD)
              )}
              onClick={() => handleMenuItemClick(canRoute(candidate.DASHBOARD))}
            >
              <NavLink to={canRoute(candidate.DASHBOARD)}>
                <i className="fa fa-home" />
                <span className="admin-nav-text">Dashboard</span>
              </NavLink>
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                canRoute(candidate.DEAL)
              )}
              onClick={() => handleMenuItemClick(canRoute(candidate.DEAL))}
            >
              <NavLink to={canRoute(candidate.DEAL)}>
                <i className="fa fa-gift" />
                <span className="admin-nav-text">Offers</span>
              </NavLink>
            </li>
            <li>
              <hr />
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                canRoute(candidate.SEARCH_JOBS)
              )}
              onClick={() =>
                handleMenuItemClick(canRoute(candidate.SEARCH_JOBS))
              }
            >
              <NavLink to={canRoute(candidate.SEARCH_JOBS)}>
                <i className="fa fa-suitcase" />
                <span className="admin-nav-text">Search Jobs</span>
              </NavLink>
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                canRoute(candidate.SAVED_JOBS)
              )}
              onClick={() =>
                handleMenuItemClick(canRoute(candidate.SAVED_JOBS))
              }
            >
              <NavLink to={canRoute(candidate.SAVED_JOBS)}>
                <i className="fa fa-share-square" />
                <span className="admin-nav-text">Saved Jobs</span>
              </NavLink>
            </li>
            <li>
              <hr />
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                canRoute(candidate.ALERTS)
              )}
              onClick={() => handleMenuItemClick(canRoute(candidate.ALERTS))}
            >
              <NavLink to={canRoute(candidate.ALERTS)}>
                <i className="fa fa-user-friends" />
                <span className="admin-nav-text">Job Alerts</span>
              </NavLink>
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                canRoute(candidate.APPLIED_JOBS)
              )}
              onClick={() =>
                handleMenuItemClick(canRoute(candidate.APPLIED_JOBS))
              }
            >
              <NavLink to={canRoute(candidate.APPLIED_JOBS)}>
                <i className="fa fa-bookmark" />
                <span className="admin-nav-text">Applied Jobs</span>
              </NavLink>
            </li>
            <li>
              <hr />
            </li>
            <li
              className={setMenuActive(activeMenuItem, canRoute(candidate.FAQ))}
              onClick={() => handleMenuItemClick(canRoute(candidate.FAQ))}
            >
              <NavLink to={canRoute(candidate.FAQ)}>
                <i className="fa fa-file-download" />
                <span className="admin-nav-text">FAQ</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default CanSidebarSection;
