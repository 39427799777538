import { Route, Routes } from "react-router-dom";
import { candidate } from "../globals/route-names";
import CanDashboardPage from "../app/pannels/candidate/components/can-dashboard";
import CanDealPage from "../app/pannels/candidate/components/can-deal";
import CanProfilePage from "../app/pannels/candidate/components/can-profile";
import CanAppliedJobs from "../app/pannels/candidate/components/can-applied-jobs";
import CanNotificationPage from "../app/pannels/candidate/components/can-notifications";
import CanFaqPage from "../app/pannels/candidate/components/can-faq";
import CanSearchJobsPage from "../app/pannels/candidate/components/can-search-jobs";
import CanSavedJobsPage from "../app/pannels/candidate/components/can-saved-jobs";
import CanJobAlertsPage from "../app/pannels/candidate/components/can-job-alerts";
import CanUpdatePasswordPage from "../app/pannels/candidate/components/can-update-password";
import Error404Page from "../app/pannels/public-user/components/pages/error404";
import Cancontact from "../app/pannels/candidate/components/can-contact";

function CandidateRoutes() {
  return (
    <Routes>
      <Route path={candidate.DASHBOARD} element={<CanDashboardPage />} />
      <Route path={candidate.DEAL} element={<CanDealPage />} />
      <Route path={candidate.PROFILE} element={<CanProfilePage />} />
      <Route path={candidate.APPLIED_JOBS} element={<CanAppliedJobs />} />
      <Route path={candidate.NOTIFICATIONS} element={<CanNotificationPage />} />
      <Route path={candidate.FAQ} element={<CanFaqPage />} />
      <Route path={candidate.SEARCH_JOBS} element={<CanSearchJobsPage />} />
      <Route path={candidate.SAVED_JOBS} element={<CanSavedJobsPage />} />
      <Route path={candidate.ALERTS} element={<CanJobAlertsPage />} />
      <Route
        path={candidate.UPDATE_PASSWORD}
        element={<CanUpdatePasswordPage />}
      />
      <Route path={candidate.CONTACT} element={<Cancontact />} />
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
}

export default CandidateRoutes;
