function SectionFaqGeneral() {
  return (
    <>
      <div className="tw-faq-section">
        <div className="accordion tw-faq" id="sf-faq-accordion">
          {/*One*/}
          <div className="accordion-item">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              aria-expanded="true"
              data-bs-target="#FAQ1"
            >
              How can Lacnar serve as a promotional platform for my medical
              service provider business ?
            </button>
            <div
              id="FAQ1"
              className="accordion-collapse collapse show"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                Lacnar is an{" "}
                <b>
                  exclusive platform for healthcare professionals and
                  organizations across Karnataka and India.
                </b>{" "}
                By posting job openings and promoting your business on Lacnar,
                you can tap into a vast network of qualified professionals and
                potential clients.
              </div>
            </div>
          </div>
          {/*Two*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ2"
              aria-expanded="false"
            >
              How can I leverage Lacnar to promote my business, products, or
              services ?
            </button>
            <div
              id="FAQ2"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                In addition to posting job openings, you can use Lacnar to share
                information about your business, products, or services. Consider
                creating blog posts, articles, or videos to highlight your
                offerings and connect with your target audience. You can also
                <b> post ads on the Lacnar website and app platform </b> to
                reach a wider, relevant and qualified health professionals and
                health industry’s network.
              </div>
            </div>
          </div>
          {/*Three*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ3"
              aria-expanded="false"
            >
              What are the benefits of using Lacnar as a promotional platform ?
            </button>
            <div
              id="FAQ3"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                As Lacnar is an unique network platform of health professionsals
                and health industry, Lacnar's{" "}
                <b>
                  {" "}
                  exclusive focus on healthcare professionals and organizations
                  in Karnataka and India
                </b>{" "}
                provides a valuable market for your business. By using Lacnar,
                you can increase your brand awareness, generate leads, and
                establish partnerships within the healthcare industry.
              </div>
            </div>
          </div>
          {/*Four*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ4"
              aria-expanded="false"
            >
              How can I create effective promotional content on Lacnar ?
            </button>
            <div
              id="FAQ4"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                Create engaging and informative content that highlights the
                unique value proposition of your medical service provider
                business. Use relevant keywords and optimize your content for
                search engines to increase visibility.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ5"
              aria-expanded="false"
            >
              What are the costs associated with using Lacnar as a promotional
              platform ?
            </button>
            <div
              id="FAQ5"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                Lacnar's pricing may vary depending on your specific needs and
                the level of promotional activity you plan to engage in. You can
                contact Lacnar's customer support for more information about
                their pricing plans. Contact information : +91 7996173337, +91
                7996173338  and email id: promotions@lacnar.com
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ6"
              aria-expanded="false"
            >
              Additional Tips
            </button>
            <div
              id="FAQ6"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                <b> Utilize Lacnar's networking features </b> to connect with
                other healthcare professionals and organizations.
              </div>
              <div className="accordion-body">
                <b> Participate in discussions and forums </b> on Lacnar to
                share your expertise and build relationships.
              </div>
              <div className="accordion-body">
                <b> Monitor industry trends and news </b> to stay updated and
                relevant in your field.
              </div>
              <div className="accordion-body">
                <b> Provide exceptional customer service </b> to build a
                positive reputation and attract repeat business.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionFaqGeneral;
