import CountUp from "react-countup";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Paper } from "@mui/material";

function SectionCandidateOverview() {
  const canAds = useSelector((state) => state.CanDashboard.canAds);

  const canProfile = useSelector((state) => state.CanDashboard.canProfile);

  const CanDashboard = useSelector((state) => state.CanDashboard.canDashboard);

  const steps = [];
  const updatededSteps = [
    ...steps,
    CanDashboard.TopBar_1,
    CanDashboard.TopBar_2,
  ];

  const ads = useSelector((state) => state.ads.ads);

  return (
    <>
      <div className="row">
        <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
          <div className="wt-admin-right-page-header">
            <h2>{canProfile?.Basic?.FirstName}</h2>
            <div className="breadcrumbs">
              <a>Home</a>
              <a>Dashboard</a>
              <span>Details</span>
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
          <NavLink to={ads?.Dashboard_Header_Link} target="_blank">
            <img
              src={ads?.Dashboard_Header}
              alt="Banner Image"
              className="img-fluid"
            />
          </NavLink>
        </div>
      </div>

      <Paper
        elevation={3}
        style={{
          background: "linear-gradient(135deg, #1aa8ed 0%, #07f4ac 100%)",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginTop: "2rem",
            marginBottom: "4rem",
            padding: "2rem 1rem",
          }}
        >
          <Stepper activeStep={0} alternativeLabel>
            {updatededSteps?.map((label) => (
              <Step key={label}>
                <StepLabel
                  style={{
                    color: "white",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {label}
                  </p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Paper>

      <div className="twm-dash-b-blocks mb-5">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body dashboard-card-2 block-gradient">
                <div className="wt-card-wrap-2">
                  <div className="wt-card-icon-2">
                    <i className="flaticon-job" />
                  </div>
                  <div className="wt-card-right wt-total-active-listing counter ">
                    <CountUp end={CanDashboard.Card_1} duration={10} />
                  </div>
                  <div className="wt-card-bottom-2 ">
                    <h4 className="m-b0">Saved Jobs</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body dashboard-card-2 block-gradient-2">
                <div className="wt-card-wrap-2">
                  <div className="wt-card-icon-2">
                    <i className="flaticon-resume" />
                  </div>
                  <div className="wt-card-right  wt-total-listing-view counter ">
                    <CountUp end={CanDashboard.Card_2} duration={10} />
                  </div>
                  <div className="wt-card-bottom-2">
                    <h4 className="m-b0">Applied Jobs</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body dashboard-card-2 block-gradient-4">
                <div className="wt-card-wrap-2">
                  <div className="wt-card-icon-2">
                    <i className="flaticon-bell" />
                  </div>
                  <div className="wt-card-right wt-total-listing-bookmarked counter ">
                    <CountUp end={CanDashboard.Card_3} duration={10} />
                  </div>
                  <div className="wt-card-bottom-2">
                    <h4 className="m-b0">Notifications</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionCandidateOverview;
