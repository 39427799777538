import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";

function ModalPopUp(props) {
  const handleClose = () => {
    props.close();
  };
  return (
    <div>
      <React.Fragment>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ padding: "1rem" }}>
            <DialogTitle id="customized-dialog-title">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: 400,
                    fontFamily: "Rubik , sans-serif",
                    marginTop: ".5rem",
                  }}
                >
                  {props.title}
                </p>
              </div>
            </DialogTitle>

            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>

          <DialogContent
            style={{
              background: "#f0f6fe",
              borderTop: "1px solid #E4E8EE",
              width: "530px",
            }}
          >
            {props.children}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    </div>
  );
}

export default ModalPopUp;
