import React, { useState, useEffect } from "react";
import {
  IconButton,
  Tooltip,
  Snackbar,
  CircularProgress,
  Button,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../../../../config/fbConfig";

function SectionCandidateEducationInfo({ Qualifications, userUUID }) {
  console.log(Qualifications);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({
    ug: "",
    pg: "",
    phd: "",
    specialization: "",
    others: "",
    file: null,
  });

  console.log(formData);
  const generateText = (data) => {
    return Object.keys(data)
      .map((key) => {
        if (key === "file") {
          // Return appropriate message for the file field
          return data[key] === null
            ? "Please upload these files in PDF"
            : "Uploaded file: " + data[key]; // Assuming file is a string, not an array
        }
        // For other keys, just return the value itself
        return `${data[key]}`;
      })
      .join(","); // Join the fields with a space or use "_" for underscore
  };

  // Usage
  const textMessages = generateText(formData);
  console.log(textMessages);

  const [fileUrl, setFileUrl] = useState(null);
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, msg: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (Qualifications) {
      setFormData({
        ug: Qualifications.UG || "",
        pg: Qualifications.PG || "",
        phd: Qualifications.PhD || "",
        specialization: Qualifications.Specialization || "",
        others: Qualifications.Others || "",
        file: Qualifications.Documents.file || null,
      });
      setFileUrl(Qualifications.Documents.file || null);
    }
  }, [Qualifications]);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ open: false, msg: "" });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const file = files[0];

    if (file) {
      if (file.type !== "application/pdf") {
        setErrors({ file: "Only PDF files are allowed" });
        return;
      }

      if (file.size > 3 * 1024 * 1024) {
        setErrors({ file: "File size must be less than 3 MB" });
        return;
      }

      setFormData((prevData) => ({ ...prevData, file }));
      setErrors({});
      const url = URL.createObjectURL(file);
      setFileUrl(url);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (formData.file === null) {
      newErrors.file = "File is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!validate()) {
      setIsLoading(false);
      return;
    }

    const data = {
      Qualifications: {
        UG: formData.ug,
        PG: formData.pg,
        PhD: formData.phd,
        Specialization: formData.specialization,
        Others: formData.others,
        Documents: {},
      },
    };

    if (formData.file) {
      const fileName = `document-${Date.now()}`;
      const storageRef = ref(storage, `users/${userUUID}/${fileName}`);
      try {
        await uploadBytes(storageRef, formData.file);
        const url = await getDownloadURL(storageRef);
        data.Qualifications.Documents.file = url;
      } catch (error) {
        console.error("Error uploading document:", error);
      }
    }

    const docRef = doc(db, "Verify", userUUID);
    await setDoc(docRef, data, { merge: true });
    setSnackbar({ open: true, msg: "Saved successfully" });
    setIsEditMode(false);
    setFileUrl(null);
    setErrors({});
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="panel panel-default">
        <div className="panel-heading wt-panel-heading p-a20">
          <h4 className="panel-title m-a0">
            Academic Information &nbsp; (
            <span
              style={{
                color:
                  Qualifications?.Status === "Not Updated"
                    ? "red"
                    : Qualifications?.Status === "Not Verified"
                    ? "blue"
                    : "green",
              }}
            >
              {Qualifications?.Status}
            </span>
            )
          </h4>
        </div>
        <div className="edit-button-end">
          <Tooltip title={isEditMode ? "Close" : "Edit"}>
            <IconButton onClick={() => setIsEditMode(!isEditMode)}>
              {isEditMode ? (
                <CloseIcon fontSize="small" />
              ) : (
                <EditIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </div>
        <div className="panel-body wt-panel-body p-a20 m-b30">
          <div className="row">
            {["ug", "pg", "phd", "specialization", "others"].map((name) => (
              <div key={name} className="col-md-6">
                <div className="form-group">
                  <label style={{ marginBottom: "3px" }}>
                    {name.charAt(0).toUpperCase() + name.slice(1)}
                  </label>
                  <input
                    type="text"
                    name={name}
                    className="form-control"
                    value={formData[name]}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                    placeholder={`Enter ${
                      name.charAt(0).toUpperCase() + name.slice(1)
                    }`}
                    style={{
                      backgroundColor: isEditMode ? "#14ca4f54" : "#14ca4f2e",
                    }}
                  />
                  {errors[name] && (
                    <div className="error-message" style={{ color: "red" }}>
                      {errors[name]}
                    </div>
                  )}
                </div>
              </div>
            ))}
            {isEditMode && (
              <div>
                <h4
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "1rem",
                  }}
                >
                  Uploaded Document
                </h4>
                <h5>{textMessages}</h5>
                <div>
                  {fileUrl ? (
                    <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                      View Document
                    </a>
                  ) : (
                    <p>No file uploaded</p>
                  )}
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={handleFileChange}
                    disabled={!isEditMode}
                  />
                  {errors.file && (
                    <div className="error-message" style={{ color: "red" }}>
                      {errors.file}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Button
        type="submit"
        variant="contained"
        disabled={isLoading}
        style={{
          marginTop: "2rem",
          backgroundColor: isEditMode ? "#14ca4f" : "#14ca4f2e",
          color: "white",
        }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Save Changes"}
      </Button>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbar.msg}
      />
    </form>
  );
}

export default SectionCandidateEducationInfo;
