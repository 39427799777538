import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { base } from "../globals/route-names";
import PublicUserLayout from "../layouts/public-user-layout";
import CandidateLayout from "../layouts/candidate-layout";
import EmployerLayout from "../layouts/employer-layout";

import LoginPage from "../app/pannels/public-user/components/pages/login";

function AppRoutes() {
  const isLoggedIn = useSelector((state) => state.AuthStatus.loginStatus);

  return (
    <Routes>
      {isLoggedIn ? (
        <>
          <Route
            path={base.CANDIDATE_PRE + "/*"}
            element={<CandidateLayout />}
          />
          <Route path={base.EMPLOYER_PRE + "/*"} element={<EmployerLayout />} />
          <Route path={base.PUBLIC_PRE + "/*"} element={<PublicUserLayout />} />
        </>
      ) : (
        <>
          <Route path={base.CANDIDATE_PRE + "/*"} element={<LoginPage />} />
          <Route path={base.EMPLOYER_PRE + "/*"} element={<LoginPage />} />
          <Route path={base.AD_PRE + "/*"} element={<LoginPage />} />
          <Route path={base.PUBLIC_PRE + "/*"} element={<PublicUserLayout />} />
        </>
      )}
    </Routes>
  );
}

export default AppRoutes;
