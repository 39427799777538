import { publicUser } from "../../../../../../globals/route-names";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchBlogs } from "../../../../../../reducers/public-user/blogSlice";
import { useDispatch, useSelector } from "react-redux";

function SectionBlogsSidebar({ id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const blogs = useSelector((state) => state.blogList.blogs);

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  useEffect(() => {
    console.log("Blogs updated:", blogs);
  }, [blogs]);

  const handleNavigate = (id) => {
    navigate(`${publicUser.blog.DETAIL}/${id}`);
  };

  // Filter out the blog with the given `id` prop
  const filteredBlogs = blogs?.filter((item) => item.Id !== id);

  return (
    <div className="side-bar">
      <div className="widget recent-posts-entry">
        <h4 className="section-head-small mb-4">Recent Articles</h4>
        <div className="section-content">
          <div className="widget-post-bx">
            {filteredBlogs?.slice(0, 6).map((item) => (
              <div
                key={item.Id}
                className="widget-post clearfix mb-4"
                onClick={() => handleNavigate(item.Id)}
                style={{ cursor: "pointer" }}
              >
                <div className="wt-post-media">
                  <img src={item.ImgUrl} alt={item.Title} />
                </div>
                <div className="wt-post-info">
                  <div className="wt-post-header">
                    <h5
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        lineHeight: "1.5",
                        maxWidth: "16rem",
                      }}
                    >
                      {item.Title}
                    </h5>
                    <p
                      style={{
                        display: "block",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "100%",
                        maxWidth: "300px",
                      }}
                    >
                      {item.Description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionBlogsSidebar;
