import React, { useState } from "react";

import { IconButton, Tooltip, Snackbar, Button } from "@material-ui/core";
import { Edit as EditIcon, Close as CloseIcon } from "@mui/icons-material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../../config/fbConfig";
import { locationOptions } from "../../../../common/static-data";

const validationSchema = Yup.object({
  address_1: Yup.string().required("Address Line 1 is required"),
  address_2: Yup.string().required("Address Line 2 is required"),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  pincode: Yup.string().max(6).required("Pincode is required"),
});

const states = [
  { label: "Karnataka", value: "Karnataka" },
  { label: "Karnataka", value: "Karnataka" },
];

function SectionEmployeeAddressInfo({ Address, userUUID }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, msg: "" });

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ open: false, msg: "" });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      Address: {
        Address_1: values.address_1,
        Address_2: values.address_2,
        State: values.state,
        District: values.district,
        Pincode: values.pincode,
      },
    };

    try {
      const docRef = doc(db, "Verify", userUUID);
      await setDoc(docRef, data, { merge: true });

      setSnackbar({ open: true, msg: "Saved successfully" });
      setIsEditMode(false);
    } catch (error) {
      console.error("Error writing document:", error);
      setSnackbar({ open: true, msg: "Error saving data" });
    } finally {
      setSubmitting(false);
    }
  };

  const formFields = [
    {
      name: "address_1",
      label: "Address Line 1",
      placeholder: "Enter Address Line 1",
      type: "text",
    },
    {
      name: "address_2",
      label: "Address Line 2",
      placeholder: "Mysuru, Karnataka 570023",
      type: "text",
    },
    { name: "state", label: "State", options: states },
    { name: "district", label: "District", options: locationOptions },
    {
      name: "pincode",
      label: "Pincode",
      placeholder: "75462",
      type: "number",
      pattern: "[0-9]*",
    },
  ];

  return (
    <Formik
      initialValues={{
        address_1: Address?.Address_1 || "",
        address_2: Address?.Address_2 || "",
        state: Address?.State || "",
        district: Address?.District || "",
        pincode: Address?.Pincode || "",
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-title m-a0">
                Address Information &nbsp; (&nbsp;
                <span
                  style={{
                    color:
                      Address?.Status === "Not Updated"
                        ? "red"
                        : Address?.Status === "Not Verified"
                        ? "blue"
                        : "green",
                  }}
                >
                  {Address?.Status}
                </span>
                &nbsp; )
              </h4>
            </div>
            <div className="edit-button-end">
              <Tooltip title={isEditMode ? "Close" : "Edit"}>
                <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                  {isEditMode ? (
                    <CloseIcon fontSize="small" />
                  ) : (
                    <EditIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <div className="panel-body wt-panel-body p-a20 m-b30">
              <div className="row">
                {formFields.map(({ name, label, options, ...rest }) => (
                  <div
                    key={name}
                    className={`col-xl-${options ? 6 : 12} col-lg-6 col-md-12`}
                  >
                    <div className="form-group city-outer-bx has-feedback">
                      <label>{label}</label>
                      <Field
                        as={options ? "select" : "input"}
                        name={name}
                        className="form-control"
                        disabled={!isEditMode}
                        style={{
                          backgroundColor:
                            isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                        }}
                        {...rest}
                      >
                        {options && (
                          <>
                            <option value="">Select {label}</option>
                            {options.slice(1).map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name={name}
                        className="error-message"
                        style={{ color: "red" }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-left">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isEditMode || isSubmitting}
                  style={{
                    backgroundColor:
                      isEditMode === true ? "#14ca4f" : "#14ca4f2e",
                  }}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
          <Snackbar
            open={snackbar.open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={handleClose}
            message={snackbar.msg}
            action={
              <IconButton size="small" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Form>
      )}
    </Formik>
  );
}

export default SectionEmployeeAddressInfo;
