import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { locationOptions } from "../../../common/static-data";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { db, firestore } from "../../../../config/fbConfig";
import Index from "../../candidate/common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpCandidateSearch } from "../../../../reducers/employer/EmpCandidateSearchSlice";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";
import { employer, empRoute } from "../../../../globals/route-names";
import JobViewPopup from "../../../common/popups/popup-jobs-emp";

function EmpCandidatesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const collectionName = "Public";
  const docName = "Designation";

  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const empInventory = useSelector((state) => state.EmpDashboard.empInventory);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [selectedJob, setSelectedJob] = useState(null); // State for selected job

  const [designationOptions, setDesignationOptions] = useState([]);
  const [specializationOptions, setSpecializationOptions] = useState([]);

  const [selectedDesignation, setSelectedDesignation] =
    useState("All Designation");
  const [selectedSpecialization, setSelectedSpecialization] =
    useState("All Specialization");
  const [selectedLocation, setSelectedLocation] = useState("All Locations");

  const handleViewClick = (job) => {
    setSelectedJob(job); // Set the selected job data
  };

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  const updatedItems = data?.map((item) => {
    const milliseconds = item.Date?.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date?.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const newDate = moment(date).format("MMM Do YYYY - hh:mm:A");

    return {
      ...item,
      formattedDate: newDate,
    };
  });

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpCandidateSearch(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch]);

  const handleSearch = async (event) => {
    if (event) event.preventDefault();
    try {
      const filters = [];

      if (selectedDesignation !== "All Designation") {
        filters.push(where("Designation", "==", selectedDesignation));
      }
      if (selectedSpecialization !== "All Specialization") {
        filters.push(where("Specialization", "==", selectedSpecialization));
      }
      if (selectedLocation !== "All Locations") {
        filters.push(where("District", "==", selectedLocation));
      }

      let q;
      if (filters.length > 0) {
        q = query(collection(firestore, "Candidates"), ...filters);
      } else {
        q = query(collection(firestore, "Candidates"));
      }

      const querySnapshot = await getDocs(q);
      const jobs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(jobs);
    } catch (error) {
      console.error("Error getting documents: ", error);
    }
  };

  const handleSaveJobs = async (job) => {
    try {
      const jobData = {
        CandidatesId: job.CandidatesId || "",
        Name: job?.Name || "",
        CreatedAt: serverTimestamp(),
        Designation: job?.Designation || "",
        District: job?.District || "",
        Experience: job?.Experience || "",
        Gender: job?.Gender || "",
        Others: job?.Others || "",
        PhD: job?.PhD ?? "",
        PostGraduate: job?.PostGraduate || "",
        Posts: job?.Posts || "",
        Specialization: job?.Specialization || "",
        UnderGraduate: job?.UnderGraduate || "",
        State: job?.State || "",
        Status: job?.Status || "",
        id: job?.id || "",
      };

      const docRef = doc(
        firestore,
        "Users",
        userUUID,
        "BookmarkResume",
        job.id
      );
      await setDoc(docRef, jobData);
      setOpen(true);
      setMsg("Candidate Saved Successfully");
    } catch (error) {
      console.error("Error saving job: ", error);
      setMsg("Error: " + error.message);
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, collectionName, docName),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const docData = docSnapshot.data();
          if (docData) {
            const fetchedData = Object.entries(docData).map(([key, value]) => ({
              Id: key,
              label: value.Tag,
            }));

            const defaultValue = [
              {
                Id: "alldesignation",
                label: "All Designation",
              },
              ...fetchedData,
            ];

            setDesignationOptions(defaultValue);
          }
        }
      }
    );
    return () => unsubscribe();
  }, [collectionName, docName]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "Public", "Specialization"),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const docData = docSnapshot.data();
          if (docData) {
            const fetchedData = Object.entries(docData).map(([key, value]) => ({
              Id: key,
              label: value.Tag,
            }));

            const defaultValue = [
              {
                Id: "alldesignation",
                label: "All Specialization",
              },
              ...fetchedData,
            ];

            setSpecializationOptions(defaultValue);
          }
        }
      }
    );
    return () => unsubscribe();
  }, []);

  return (
    <>
      {loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Candidates</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Candidates</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Search_Header_Link} target="_blank">
                  <img
                    src={empAds?.Search_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {empInventory?.Database_Remaining === 0 ? (
            <Container maxWidth="md" style={{ marginBottom: "2rem" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40vh",
                  textAlign: "center",
                  bgcolor: "background.default",
                  p: 3,
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              >
                <PeopleIcon
                  style={{ color: "#39CE6D" }}
                  sx={{ fontSize: 60, mb: 2 }}
                />
                <Typography variant="h5" gutterBottom>
                  Your Database Inventory is currently empty
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                  Don’t miss out on potential candidates - purchase more
                  inventory today to keep your hiring process on track!
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ backgroundColor: "#39CE6D" }}
                  onClick={() => {
                    navigate(empRoute(employer.PRICING));
                  }}
                  sx={{ textTransform: "none", px: 4 }}
                >
                  Purchase Inventory
                </Button>
              </Box>
            </Container>
          ) : (
            <>
              <Box
                sx={{
                  padding: 3,
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Search for Jobs
                </Typography>
                <form onSubmit={handleSearch}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Select
                        fullWidth
                        size="small"
                        value={selectedDesignation}
                        onChange={(e) => setSelectedDesignation(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Designation" }}
                      >
                        {designationOptions.map((option) => (
                          <MenuItem key={option.label} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Select
                        fullWidth
                        size="small"
                        value={selectedSpecialization}
                        onChange={(e) =>
                          setSelectedSpecialization(e.target.value)
                        }
                        displayEmpty
                        inputProps={{ "aria-label": "Specialization" }}
                      >
                        {specializationOptions.map((option) => (
                          <MenuItem key={option.label} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Select
                        size="small"
                        fullWidth
                        value={selectedLocation}
                        onChange={(e) => setSelectedLocation(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Location" }}
                      >
                        {locationOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ backgroundColor: "#14ca4f" }}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
              {data?.length > 0 ? (
                <div className="twm-pro-view-chart-wrap">
                  <div className="col-lg-12 col-md-12 mb-4">
                    <div className="panel panel-default site-bg-white m-t30">
                      <div className="panel-heading wt-panel-heading p-a20">
                        <h4 className="panel-tittle m-a0">
                          <i className="far fa-list-alt" />
                          All Candidates
                        </h4>
                      </div>
                      <div className="panel-body wt-panel-body">
                        <div className="twm-D_table p-a20 table-responsive">
                          <table
                            id="candidate_data_table"
                            className="table table-bordered"
                          >
                            <thead>
                              <tr>
                                <th>Sl.No</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Date</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {updatedItems?.map((item, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <div className="twm-DT-candidates-list">
                                      <div className="twm-mid-content">
                                        <a href="#" className="twm-job-title">
                                          <h4>{item.Name}</h4>
                                          <p className="twm-candidate-address">
                                            {item.District}, {item.State},
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.Designation}</td>
                                  <td>{item.formattedDate}</td>
                                  <td>
                                    <div className="twm-table-controls">
                                      <ul className="twm-DT-controls-icon list-unstyled d-flex justify-content-end">
                                        <li style={{ marginRight: "12px" }}>
                                          <a
                                            data-bs-toggle="modal"
                                            href="#saved-jobs-view"
                                            role="button"
                                            className="custom-toltip"
                                            onClick={() =>
                                              handleViewClick(item)
                                            } // Set selected job on click
                                          >
                                            <Tooltip title={"View Candidate"}>
                                              <span>
                                                <IconButton>
                                                  <RemoveRedEyeIcon
                                                    style={{
                                                      fontSize: "15px",
                                                    }}
                                                  />
                                                </IconButton>
                                              </span>
                                            </Tooltip>
                                          </a>
                                        </li>
                                        <li>
                                          <Tooltip title="Save Candidate">
                                            <IconButton
                                              onClick={() =>
                                                handleSaveJobs(item)
                                              }
                                            >
                                              <BookmarkIcon
                                                style={{ fontSize: "15px" }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <th />
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Date</th>
                                <th>Action</th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                  }}
                >
                  <Index />
                </div>
              )}
            </>
          )}

          <Snackbar
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={2000}
            onClose={handleClose}
            message={msg}
            action={
              <IconButton size="small" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />

          <JobViewPopup selectedJob={selectedJob} />

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-4">
            <NavLink to={empAds?.Search_Footer_Link} target="_blank">
              <img
                src={empAds?.Search_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}
export default EmpCandidatesPage;
