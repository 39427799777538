import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MyFormComponent from "./add-ticketForm";
import ModalPopUp from "../../../common/ModalPopUp";
import Index from "../../candidate/common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import CircularProgress from "../../../common/loading/Index";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpTickets } from "../../../../reducers/employer/EmpTicketsSlice";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";

function EmpTicketsPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const { EmpTickets, loading: ticketsLoading } = useSelector(
    (state) => state.EmpTickets
  );
  console.log(EmpTickets);

  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpTickets(userUUID));
    if (loading) dispatch(fetchGeneralempAds());
  }, [dispatch, loading, userUUID]);

  const updatedData = useMemo(() => {
    return EmpTickets?.map((item) => {
      const createdMilliseconds =
        item.CreatedAt?.seconds * 1000 + item.CreatedAt?.nanoseconds / 1000000;
      const createdDate = new Date(createdMilliseconds);

      const resolvedMilliseconds =
        item.ReslovedDate?.seconds * 1000 +
        item.ReslovedDate?.nanoseconds / 1000000;

      const resolvedDate = item.ReslovedDate
        ? new Date(resolvedMilliseconds)
        : null;

      return {
        ...item,
        date: createdDate,
        formattedDate: moment(createdDate).format("MMM Do YYYY, h:mm A"),
        resolvedDate: resolvedDate,
        formattedResolvedDate: resolvedDate
          ? moment(resolvedDate).format("MMM Do YYYY, h:mm A")
          : null,
      };
    }).sort((a, b) => b.date - a.date);
  }, [EmpTickets]);

  console.log(updatedData);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {ticketsLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Tickets</h2>
                <div className="breadcrumbs">
                  <NavLink to="#">Home</NavLink>
                  <NavLink to="#">Dashboard</NavLink>
                  <span>Tickets</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Tickets_Header_Link} target="_blank">
                  <img
                    src={empAds?.Tickets_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          <div className="twm-pro-view-chart-wrap">
            <div className="col-lg-12 col-md-12 mb-4">
              <div className="panel panel-default site-bg-white m-t30">
                <div className="panel-heading wt-panel-heading p-a20">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4 className="panel-title m-a0">
                      <i className="far fa-bookmark" /> Tickets
                    </h4>
                    <button
                      onClick={() => setOpen(true)}
                      className="site-button m-r5"
                    >
                      Add Ticket
                    </button>
                  </div>
                </div>

                {EmpTickets?.length > 0 ? (
                  <div className="panel-body wt-panel-body">
                    <div className="twm-D_table p-a20 table-responsive">
                      <table
                        id="jobs_bookmark_table"
                        className="table table-bordered twm-bookmark-list-wrap"
                      >
                        <thead>
                          <tr>
                            <th>Sl.No</th>
                            <th>Ticket Details</th>

                            <th>Created Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {updatedData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td style={{ maxWidth: "500px" }}>
                                <div className="twm-bookmark-list">
                                  <div className="twm-mid-content">
                                    <a href="#" className="twm-job-title">
                                      <h4>{item.Subject}</h4>
                                    </a>
                                    {item.Description}
                                    {item.Remarks && (
                                      <>
                                        <hr style={{ height: "3px" }} />
                                        <span
                                          style={{
                                            color: "#046434",
                                            fontWeight: 500,
                                          }}
                                        >
                                          Remarks &nbsp; :{" "}
                                        </span>{" "}
                                        &nbsp;
                                        {item.Remarks}
                                      </>
                                    )}
                                    {item.formattedResolvedDate && (
                                      <>
                                        <br />
                                        <span
                                          style={{
                                            color: "#046434",
                                            fontWeight: 500,
                                          }}
                                        >
                                          Resolved Date &nbsp;:{" "}
                                        </span>
                                        &nbsp;
                                        {item.formattedResolvedDate}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>{item.formattedDate}</td>
                              <td>
                                <span className="text-clr-green2">
                                  {item.Status === true ? "Open" : "Closed"}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Sl.No</th>
                            <th>Ticket Title</th>

                            <th>Created Date</th>
                            <th>Status</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "3rem",
                    }}
                  >
                    <Index />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-4">
            <NavLink to={empAds?.Tickets_Footer_Link} target="_blank">
              <img
                src={empAds?.Tickets_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>

          <ModalPopUp
            title="Add Ticket Form"
            open={open}
            close={handleClose}
            children={<MyFormComponent handleCloses={handleClose} />}
          />
        </>
      )}
    </>
  );
}

export default EmpTicketsPage;
